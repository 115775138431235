import { Colors, FontFamily, getFont } from '@freespee/ui';
import { css } from 'emotion';

export const host = css({
  color: Colors.AVERAGE_GREY,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '32px 0',
  '& fs-icon': {
    marginRight: '10px',
  },

  ...getFont(FontFamily.BODY),
  fontSize: 14,
  letterSpacing: 0.3,
});

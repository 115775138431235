import { Component, Input } from '@angular/core';

import { commonStyles } from '#app/app.styles';
import { Configuration } from '#app/config/config.interface';
import * as css from './slot-selector-message.styles';

@Component({
  selector: 'app-slot-selector-message',
  template: `
    <div [class]="css.messagePart">
      <h2 [class]="commonStyles.header">
        {{
          configuration.texts['freespeeConnectModuleScheduleSlotSelectionTitle']
        }}
      </h2>
      <p [class]="commonStyles.paragraph">
        {{
          configuration.texts[
            'freespeeConnectModuleScheduleSlotSelectionSubTitle'
          ]
        }}
      </p>
      <div [class]="css.firstAvailableLabel">
        {{
          configuration.texts[
            'freespeeConnectModuleScheduleSlotSelectionSuggestionTitle'
          ]
        }}
      </div>
    </div>
  `,
})
export class SlotSelectorMessageComponent {
  css = css;
  commonStyles = commonStyles;

  constructor() {}

  @Input()
  configuration: Configuration;
}

import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  trackScheduleFailed,
  trackScheduleSuccess,
} from '#app/event-tracking/event-tracking';
import { ConnectModule } from '#gql';
import { Configuration, Mode } from '../config/config.interface';
import { getErrorTexts } from '../util/error-text.util';
import { getLocale, getTimezone } from '../util/locale.util';
import { SlotSchedulingEvent } from './components/slot-selector/slot-selector.interface';
import { SlotsDay } from './scheduling.interface';
import { SchedulingView } from './scheduling.interface';
import * as css from './scheduling.styles';
import { ScheduleSlotParams } from './services/slot.interface';
import { SlotService } from './services/slot.service';

@Component({
  selector: 'app-scheduling',
  template: `
    <app-slot-selector
      *ngIf="view === View.selectingSlot"
      [suggestedSlots]="suggestedSlots"
      [allSlots]="allSlots"
      [module]="module"
      [slotTimezone]="slotTimezone"
      (slotBooked)="scheduleSlot($event)"
      [configuration]="configuration"
    ></app-slot-selector>

    <app-confirmation
      *ngIf="view === View.slotConfirmed"
      [header]="confirmationHeader"
      [text]="confirmationMessage"
    ></app-confirmation>

    <app-schedule-failed
      [header]="confirmationHeader"
      [text]="confirmationMessage"
      [tryAgainText]="configuration.texts['freespeeConnectModuleScheduleTryAgain']"
      (tryAgain)="retrySchedule()"
      *ngIf="view === View.scheduleFailed"
    ></app-schedule-failed>

    <app-notification
      *ngIf="error && module === ConnectModule.SCHEDULE"
      [header]="error.header"
      [message]="error.message"
      (close)="error = undefined"
    ></app-notification>
  `,
})
export class SchedulingComponent implements OnInit {
  constructor(private slotService: SlotService) {}
  css = css;
  View = SchedulingView;
  ConnectModule = ConnectModule;

  @HostBinding('class')
  hostClass = css.host;

  @Input()
  view: SchedulingView = SchedulingView.selectingSlot;

  @Input()
  configuration: Configuration;

  @Input()
  module: ConnectModule;

  @Input()
  appId: string;

  @Input()
  dncsSessionId: string;

  @Input()
  answeringNumberId: string;

  @Input()
  accountUuid: string;

  @Output()
  viewChanged = new EventEmitter<SchedulingView>();

  suggestedSlots: SlotsDay[] = [];
  allSlots: SlotsDay[] = [];
  slotTimezone: string;
  confirmationMessage = '';
  confirmationHeader = '';
  error?: {
    header: string;
    message: string;
  };

  lastBookingAttempted: SlotSchedulingEvent;

  ngOnInit() {
    this.slotService
      .getAvailableSlots({
        appId: this.appId,
        locale: getLocale(),
        context: 'qwerty',
        timezone: getTimezone(),
        accountUuid: this.accountUuid,
      })
      .then(response => {
        this.suggestedSlots = response.suggestedSlots;
        this.allSlots = response.allSlots;
        this.slotTimezone = response.timezone;
      })
      .catch((_: HttpErrorResponse) => {
        // @TODO how do we bind this to a specific error message?
        this.error = getErrorTexts(this.configuration.texts, '');
      });
  }

  scheduleSlot(event: SlotSchedulingEvent) {
    this.lastBookingAttempted = event;
    const scheduleSlotEvent: ScheduleSlotParams = {
      appId: this.appId,
      locale: getLocale(),
      context: 'qwerty',
      timezone: getTimezone(),
      phoneNumber: event.phoneNumber,
      answeringNumberId: this.answeringNumberId,
      dncsSessionId: this.dncsSessionId,
      accountUuid: this.accountUuid,
      slot: {
        start: event.slot.start,
        end: event.slot.end,
      },
    };

    if (this.configuration.mode === Mode.test) {
      this.onSlotScheduleAttemptedSucceeded(
        'The slot would have been scheduled if we were not in test mode'
      );
    } else {
      this.slotService
        .schedule(scheduleSlotEvent)
        .then(resp => {
          if (resp.meta.status === 'SUCCESS') {
            trackScheduleSuccess(scheduleSlotEvent);
            this.onSlotScheduleAttemptedSucceeded(
              resp.data.bookedSlotDisplayString
            );
          } else {
            trackScheduleFailed(scheduleSlotEvent);
            this.onSlotScheduleAttemptedFailed(
              getErrorTexts(this.configuration.texts, resp?.meta?.errorCode)
            );
          }
        })
        .catch((_: HttpErrorResponse) => {
          // @TODO how do we bind this to a specific error message?
          this.onSlotScheduleAttemptedFailed(
            getErrorTexts(this.configuration.texts, '')
          );
        });
    }
  }

  private onSlotScheduleAttemptedFailed({
    header,
    message,
  }: {
    header: string;
    message: string;
  }) {
    this.confirmationHeader = header;
    this.confirmationMessage = message;
    this.viewChanged.emit(SchedulingView.scheduleFailed);
  }

  private onSlotScheduleAttemptedSucceeded(text: string) {
    this.confirmationHeader = this.configuration.texts[
      'freespeeConnectModuleScheduleDoneTitle'
    ];
    this.confirmationMessage = text;
    this.viewChanged.emit(SchedulingView.slotConfirmed);
  }

  onCancelSlot() {
    this.viewChanged.emit(SchedulingView.selectingSlot);
  }

  retrySchedule() {
    this.viewChanged.emit(SchedulingView.selectingSlot);
    this.scheduleSlot(this.lastBookingAttempted);
  }
}

import { Colors } from '@freespee/ui';
import { css } from 'emotion';

import { FOOTER_HEIGHT } from '../../../app.styles';
export const host = css({
  backgroundColor: Colors.WHITE,
  flexBasis: FOOTER_HEIGHT,
  flexGrow: 0,
  flexShrink: 0,
  minHeight: FOOTER_HEIGHT,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const poweredBy = css({
  fill: Colors.INACTIVE_GREY,

  '&:hover': {
    fill: Colors.FREESPEE_BLUE,
  },
});

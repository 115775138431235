import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { StoredConfiguration } from './config.interface';

interface ConfigurationResponse {
  meta: {
    status: 'SUCCESS';
  };
  data: StoredConfiguration;
}

export interface GetConfigurationParameters {
  appId: string;
  context: string;
  timezone: string;
  locale: string;
}

@Injectable()
export class BackendConfigService {
  constructor(private httpClient: HttpClient) {}

  getConfig({
    appId,
    context,
    timezone,
    locale,
  }: GetConfigurationParameters): Promise<StoredConfiguration> {
    return this.httpClient
      .get(
        `${environment.backendURL}/configuration?context=${context}&timezone=${timezone}&locale=${locale}`,
        {
          headers: {
            'X-FS-APP-ID': appId,
          },
        }
      )
      .toPromise()
      .then(this.parseConfig);
  }

  private parseConfig = (
    response: ConfigurationResponse
  ): StoredConfiguration => ({
    defaultCountryCode: 'gb', // @TODO make the client figure out a good default for this (geolocation / locale from browser)
    companyName: response.data.companyName,
    colors: response.data.colors,
    texts: response.data.texts,
    modules: response.data.modules,
    images: response.data.images,
  })
}

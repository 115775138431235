import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
  FsButtonModule,
  FsIconModule,
  FsSmallCardModule,
} from '@freespee/angular';

import { AppComponent } from './app.component';
import { ConnectCommonModule } from './common/connect-common.module';
import { ConfigModule } from './config/config.module';
import { FooterComponent } from './main/components/footer/footer.component';
import { HeaderComponent } from './main/components/header/header.component';
import { CallModuleCardComponent } from './main/components/module-selector/call-module-card/call-module-card.component';
import { ModuleSelectorComponent } from './main/components/module-selector/module-selector.component';
import { SchedulingModule } from './scheduling/scheduling.module';

const routes: Routes = [{ path: '*', component: AppComponent }];

@NgModule({
  declarations: [
    AppComponent,
    CallModuleCardComponent,
    FooterComponent,
    HeaderComponent,
    ModuleSelectorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FsButtonModule,
    FsIconModule,
    FsSmallCardModule,
    HttpClientModule,
    RouterModule.forRoot(routes),

    // Connect Modules
    ConfigModule,
    SchedulingModule,
    ConnectCommonModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { IconSize } from '@freespee/angular';

import { longAnimation } from '#app/animation-times';
import * as css from './notification.styles';

@Component({
  selector: 'app-notification',
  template: `
    <div>
      <h3 [class]="css.header">{{ header }}</h3>
      <p [class]="css.message">{{ message }}</p>
    </div>
    <fs-icon
      (click)="close.emit()"
      icon="close"
      [size]="IconSize.PRIMARY"
      stroke="white"
    ></fs-icon>
  `,
  animations: [
    trigger('slideIn', [
      state('void', style({ transform: 'translateY(100%)' })),
      state('*', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        animate(`${longAnimation}ms ${longAnimation * 2}ms ease-in-out`),
      ]),
      transition(':leave', [animate(`${longAnimation}ms ease-in-out`)]),
    ]),
  ],
})
export class NotificationComponent {
  css = css;
  IconSize = IconSize;

  @Input()
  header: string;

  @Input()
  message: string;

  @Output()
  close = new EventEmitter<void>();

  constructor() {}

  @HostBinding('class')
  class = css.host;

  @HostBinding('@slideIn') slide;
}

import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  CountryCallingCode,
  CountryCode,
  getPhoneCode,
  isValidNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';

import { CountryCodeOption, countryCodeOptions } from './country-codes';
import { PhoneNumberInput } from './phone-number-input.interface';
import * as css from './phone-number-input.styles';

@Component({
  selector: 'app-number-input',
  template: `
    <div [class]="css.inputBox" *ngIf="countryCode">
      <fs-select
        [items]="countryCodes"
        (selectedValue)="countryCodeChanged($event)"
        [value]="countryCode"
      >
      </fs-select>
      <div [class]="css.countryNumber">+({{ countryNumber }})</div>
      <input
        fsInput
        [value]="phoneInputValue"
        (input)="onNumberChanged($event.target.value)"
        [placeholder]="placeholder"
        autocomplete="off"
      />
    </div>
  `,
})
export class PhoneNumberInputComponent implements OnChanges {
  css = css;
  phoneInputValue = '';
  countryNumber: CountryCallingCode;
  countryCode;
  countryCodes: CountryCodeOption[] = countryCodeOptions.map(country => ({
    label: country.label,
    labelWhenClosed: country.value.toUpperCase(),
    value: country.value.toUpperCase(),
  }));
  constructor() {}

  @Input()
  defaultCountryCode: string;

  @Input()
  placeholder: string;

  @Output()
  numberChanged = new EventEmitter<PhoneNumberInput>();

  @HostBinding('class')
  hostClass = css.host;

  ngOnChanges() {
    this.countryCode = this.defaultCountryCode.toUpperCase();
    this.countryNumber = getPhoneCode(this.countryCode);
  }

  onNumberChanged(number: string) {
    this.phoneInputValue = number;
    this.emitNumber();
  }

  countryCodeChanged(code: CountryCode) {
    this.countryCode = code;
    this.countryNumber = getPhoneCode(this.countryCode);
    this.emitNumber();
  }

  private emitNumber() {
    const numberParsed = parsePhoneNumberFromString(
      this.phoneInputValue,
      this.countryCode
    );

    const formatted = numberParsed
      ? numberParsed.formatInternational()
      : undefined;

    const number = numberParsed ? (numberParsed.number as string) : undefined;

    this.numberChanged.emit({
      localNumber: this.phoneInputValue,
      countryNumber: this.countryNumber,
      countryCode: this.countryCode,
      number: number,
      formattedInternational: formatted,
      valid: formatted ? isValidNumber(formatted) : false,
    });
  }
}

import { Configuration } from '../config/config.interface';
import {
  trackConnectClose,
  trackConnectOpen,
} from '../event-tracking/event-tracking';

export const requestCloseConnect = () => {
  window.parent.postMessage(
    { type: 'freespee-connect-internal-message', subType: 'request-close' },
    '*'
  );
};

export const connectInitiated = () => {
  if (window !== window.parent) {
    window.parent.postMessage(
      {
        type: 'freespee-connect-internal-message',
        subType: 'connect-initiated',
      },
      '*'
    );
  }
};

export const afterConfigUpdated = (config: Configuration) => {
  if (window !== window.parent) {
    window.parent.postMessage(
      {
        type: 'freespee-connect-internal-message',
        subType: 'config-updated',
        payload: config,
      },
      '*'
    );
  }
};

let listenerStarted = false;
export interface MessageListeners {
  onFullscreenModeChanged: (fullscreen: boolean) => void;
  onUpdateConfig: (config: any) => void;
  onShowView: (view: string) => void;
}
export const setupMessageListeners = ({
  onFullscreenModeChanged,
  onUpdateConfig,
  onShowView,
}: MessageListeners) => {
  if (listenerStarted) {
    console.warn('Internal Connect messaging listener already started');
    return;
  }
  listenerStarted = true;
  window.addEventListener(
    'message',
    event => {
      if (event.data.type === 'freespee-connect-internal-message') {
        switch (event.data.subType) {
          case 'open':
            trackConnectOpen();
            return;
          case 'close':
            trackConnectClose();
            return;
          case 'toggle-fullscreen':
            onFullscreenModeChanged(event.data.payload);
            return;
          case 'update-config':
            onUpdateConfig(event.data.payload);
            return;
          case 'show-view':
            onShowView(event.data.payload);
            return;
          default:
            console.error(
              'Freespee Connect: Unexpected message event type received in the Connect frame: ' +
                event.data.subType
            );
        }
      }
    },
    false
  );
};

/*
 There are a lot of views that have animations which are dependent on
 other animations. This file is an attempt to make those animations
 easier to reason about.

 */

export const shortAnimation = 100;
export const longAnimation = 200;
export const animationPause = 25;

export default {
  module: {
    select: {
      // Header margin increases, header height decreases and module selector leaves to the left
      step1: {
        timing: `${longAnimation}ms ease-in-out`,
      },
      // Header margin is removed and module enters from the right
      step2: {
        timing: `${shortAnimation}ms ease-in-out ${
          longAnimation + animationPause
        }ms`,
      },
    },
    deselect: {
      // Module slides out to the right
      step1: {
        duration: shortAnimation,
        timing: `${shortAnimation}ms ease-in-out`,
      },
      // Header margin decreases, header height increases and module selector enters from the left
      step2: {
        duration: longAnimation + shortAnimation + animationPause,
        timing: `${longAnimation}ms ease-in-out ${
          shortAnimation + animationPause
        }ms`,
      },
    },
  },
};

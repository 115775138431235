import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FsButtonModule,
  FsIconModule,
  FsInputModule,
  FsSelectModule,
  FsSkeletonsModule,
  FsSmallCardModule,
} from '@freespee/angular';

import { ConnectCommonModule } from '../common/connect-common.module';
import { ConfigModule } from '../config/config.module';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ScheduleFailedComponent } from './components/confirmation/schedule-failed.component';
import { PhoneNumberFormComponent } from './components/phone-number-input/phone-number-form.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { EmptyDayComponent } from './components/slot-selector/empty-day/empty-day.component';
import { MoreSlotsButtonComponent } from './components/slot-selector/more-slots-buttton/more-slots-button.component';
import { SlotSelectorMessageComponent } from './components/slot-selector/slot-selector-message/slot-selector-message.component';
import { SlotSelectorComponent } from './components/slot-selector/slot-selector.component';
import { SlotComponent } from './components/slot-selector/slot/slot.component';
import { SchedulingComponent } from './scheduling.component';

@NgModule({
  declarations: [
    ConfirmationComponent,
    EmptyDayComponent,
    MoreSlotsButtonComponent,
    PhoneNumberInputComponent,
    PhoneNumberFormComponent,
    ScheduleFailedComponent,
    SlotComponent,
    SlotSelectorComponent,
    SlotSelectorMessageComponent,
    SchedulingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ConfigModule,
    ConnectCommonModule,
    FormsModule,
    HttpClientModule,
    FsButtonModule,
    FsSkeletonsModule,
    FsIconModule,
    FsInputModule,
    FsSelectModule,
    FsSmallCardModule,
  ],
  providers: [],
  exports: [SchedulingComponent],
})
export class SchedulingModule {}

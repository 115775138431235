import { NgModule } from '@angular/core';
import { FsIconModule } from '@freespee/angular';

import { NotificationComponent } from './components/notification/notification.component';

@NgModule({
  declarations: [NotificationComponent],
  imports: [FsIconModule],
  providers: [],
  exports: [NotificationComponent],
})
export class ConnectCommonModule {}

import { Colors, FontFamily, getFont } from '@freespee/ui';
import { css } from 'emotion';

import { LARGE_MARGIN } from '#app/app.styles';

export const messagePart = css({
  marginLeft: LARGE_MARGIN,
  marginRight: LARGE_MARGIN,
});

export const firstAvailableLabel = css({
  ...getFont(FontFamily.HEADING),

  alignItems: 'center',
  backgroundColor: Colors.LIFE_GREEN,
  color: Colors.WHITE,
  display: 'inline-flex',
  padding: '0 12px',
  fontSize: 12,
  fontWeight: 'normal',
  height: 24,
  justifyContent: 'center',
  letterSpacing: 1.8,
  marginBottom: 8,
  marginTop: 39,
  textTransform: 'uppercase',
});

import { Colors } from '@freespee/ui';
import { css } from 'emotion';

export const host = css({
  backgroundColor: Colors.WHITE,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
});

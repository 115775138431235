import { Component, HostBinding, Input } from '@angular/core';
import { IconSize } from '@freespee/angular';

import * as css from './empty-day.styles';

@Component({
  selector: 'app-empty-day',
  template: `
    <fs-icon icon="subtract" [size]="IconSize.SMALL" stroke="#8d959d"></fs-icon>
    {{ text }}
  `,
})
export class EmptyDayComponent {
  IconSize = IconSize;

  constructor() {}

  @Input()
  text: string;

  @HostBinding('class')
  hostClass = css.host;
}

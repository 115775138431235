import { ScheduleSlotParams } from '../scheduling/services/slot.interface';
import { TrackingEvent, TrackingEventType } from './event-tracking.interface';

export function trackConnectOpen() {
  sendTrackingEvent(TrackingEventType.OPEN);
}

export function trackConnectClose() {
  sendTrackingEvent(TrackingEventType.CLOSE);
}

export function trackScheduleSuccess(payload: ScheduleSlotParams) {
  sendTrackingEvent(TrackingEventType.SCHEDULE_SUCCEEDED, payload);
}

export function trackScheduleFailed(payload: ScheduleSlotParams) {
  sendTrackingEvent(TrackingEventType.SCHEDULE_FAILED, payload);
}

function sendTrackingEvent(type: TrackingEventType, payload?: any) {
  const event: TrackingEvent = {
    sender: 'freespee-connect',
    type,
    payload,
  };
  window.parent.postMessage(event, '*');
}

import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { IconSize } from '@freespee/angular';
import { Colors } from '@freespee/ui';

import { commonStyles } from '../../../app.styles';
import * as css from './confirmation.styles';

@Component({
  selector: 'app-schedule-failed',
  template: `
    <fs-icon
      [class]="css.confirmIcon"
      icon="not_available"
      [size]="IconSize.XLARGE"
      [stroke]="Colors.NO_RED"
    ></fs-icon>
    <h2 [class]="commonStyles.header">{{ header }}</h2>
    <p [class]="commonStyles.paragraph">{{ text }}</p>

    <button fsButton="secondary" (click)="tryAgain.emit()">
      {{ tryAgainText }}
    </button>
  `,
})
export class ScheduleFailedComponent {
  css = css;
  commonStyles = commonStyles;
  IconSize = IconSize;
  Colors = Colors;

  @HostBinding('class')
  hostClass = css.host;

  constructor() {}

  @Input()
  header: string;
  @Input()
  text: string;

  @Input()
  tryAgainText: string;

  @Output()
  tryAgain = new EventEmitter<void>();
}

export enum HeaderType {
  MAIN,
  MODULE,
}

export enum HeaderState {
  SMALL,
  GROWING,
  LARGE,
  SHRINKING,
}

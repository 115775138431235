import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';
import { IconSize } from '@freespee/angular';

import { commonStyles } from '../../../app.styles';
import * as css from './confirmation.styles';

@Component({
  selector: 'app-confirmation',
  template: `
    <fs-icon
      [class]="css.confirmIcon"
      icon="check_circle"
      [size]="IconSize.XLARGE"
      stroke="#1fe6ce"
      @pulse
      (@pulse.done)="onIconAnimationDone()"
    ></fs-icon>
    <div
      [@fadeAndSlide]="iconAnimationDone"
      [class]="css.textContainer(iconAnimationDone)"
    >
      <h2 [class]="commonStyles.header">{{ header }}</h2>
      <p [class]="commonStyles.paragraph">{{ text }}</p>
    </div>
  `,
  animations: [
    trigger('fadeAndSlide', [
      transition('false => true', [
        animate(
          `200ms`,
          keyframes([
            style({ transform: 'translateY(24px)', opacity: 0 }),
            style({ transform: 'translateY(0)', opacity: 1 }),
          ])
        ),
      ]),
    ]),
    trigger('pulse', [
      transition(':enter', [
        animate(
          '0.7s',
          keyframes([
            style({
              transform: 'translateY(32px) scale(1)',
              opacity: 0,
              offset: 0,
            }),
            style({
              transform: 'translateY(32px) scale(1.5)',
              opacity: 1,
              offset: 0.35,
            }),
            style({ transform: 'translateY(32px) scale(1)', offset: 0.75 }),
            style({ transform: 'translateY(32px) scale(1)', offset: 0.8 }),
            style({ transform: 'translateY(0)', offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class ConfirmationComponent {
  css = css;
  commonStyles = commonStyles;
  IconSize = IconSize;
  iconAnimationDone = false;

  @HostBinding('class')
  hostClass = css.host;

  constructor() {}

  @Input()
  header: string;
  @Input()
  text: string;

  onIconAnimationDone() {
    this.iconAnimationDone = true;
  }
}

import { Colors, FontFamily, getFont } from '@freespee/ui';
import { css } from 'emotion';

import { SMALL_MARGIN } from '../../../app.styles';

export const host = css({
  marginTop: SMALL_MARGIN,
  ...getFont(FontFamily.BODY),
  fontSize: 16,
  fontWeight: 'normal',
  lineHeight: 1.13,
  letterSpacing: 0.35,
});

export const countryNumber = css({
  flexBasis: 48,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: ' #8d959d',
  paddingRight: 5,
  borderLeft: `1px solid ${Colors.DAYLIGHT_WHITE}`,
  height: '100%',
});

export const inputBox = css({
  width: '100%',
  height: 56,
  backgroundColor: Colors.WHITE,
  display: 'flex',
  alignItems: 'center',

  '& > input': {
    border: 'none',
    outline: 'none',
    height: '100%',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    padding: 0,
    backgroundColor: 'transparent',
    flexGrow: 1,
    color: Colors.MIDNIGHT_BLACK,
  },

  '& ul': {
    paddingInlineStart: 0,
    marginBlockStart: 0,
    margin: 0,
    padding: 0,
  },

  '& fs-select > div': {
    backgroundColor: Colors.WHITE,
    border: 'none',

    '&:focus': {
      border: 'none',
    },
  },
});

import { Injectable } from '@angular/core';
import { Colors } from '@freespee/ui';

import { defaultTexts } from './config.constants';
import { Configuration, ConfigurationUpdate, Mode } from './config.interface';
import { validateConfig } from './config.validation';

@Injectable()
export class ConfigService {
  private configuration: Configuration = {
    mode: Mode.default,
    closeOnBlur: true,
    defaultCountryCode: 'gb',
    colors: {
      action: {
        color: Colors.MIDNIGHT_BLACK,
        invertedText: true,
      },
      base: {
        color: Colors.FREESPEE_BLUE,
        invertedText: false,
      },
    },
    texts: {
      ...defaultTexts,
    },
    companyName: '',
    modules: [],
    images: {},
  };

  get() {
    return this.configuration;
  }

  update(config: ConfigurationUpdate) {
    const valid = validateConfig(config);

    if (valid) {
      this.configuration = {
        ...this.configuration,
        ...config,
        colors: {
          ...this.configuration.colors,
          ...config.colors,
        },
        texts: config.texts || this.configuration.texts,
      };
    }
  }
}

export interface Slot {
  available: boolean;
  start: Date;
  end: Date;
  label: string;
  dateLabel: string;
}

export interface SlotsDay {
  label: string;
  subLabel: string;
  slots: Slot[];
}

export interface Slots {
  suggestedSlots: SlotsDay[];
  allSlots: SlotsDay[];
  timezone: string;
}

export enum SchedulingView {
  selectingSlot,
  slotConfirmed,
  scheduleFailed,
}

/**
 * Attempts to find a sessionId from a DNCS cookie, supports:
 *
 * __fs_dncs_sessionid_<organisation tag>
 * __fs_dncs_sessionid_<account uuid>
 */
export const getSessionIdFromCookie = (): string => {
  const COOKIE_NAME_PREFIX = '__fs_dncs_sessionid_';

  let name: string;
  let value: string;
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    name = cookies[i].substr(0, cookies[i].indexOf('='));
    value = cookies[i].substr(cookies[i].indexOf('=') + 1);

    if (name.includes(COOKIE_NAME_PREFIX)) {
      return unescape(value);
    }
  }

  return '';
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '#environments/environment';
import { Slot, Slots, SlotsDay } from '../scheduling.interface';
import {
  GetSlotsParams,
  ScheduleResponse,
  ScheduleSlotParams,
  SlotResponsePart,
  SlotsDayResponsePart,
  SlotsResponse,
} from './slot.interface';

@Injectable({
  providedIn: 'root',
})
export class SlotService {
  constructor(private httpClient: HttpClient) {}

  getAvailableSlots({
    context,
    locale,
    appId,
    timezone,
    accountUuid,
  }: GetSlotsParams): Promise<Slots> {
    return this.httpClient
      .get(
        `${environment.backendURL}/schedule/available?context=${context}&timezone=${timezone}&locale=${locale}&accountUuid=${accountUuid}`,
        {
          headers: {
            'X-FS-APP-ID': appId,
          },
        }
      )
      .toPromise()
      .then(this.slotsFromResponse);
  }

  schedule({
    appId,
    context,
    timezone,
    locale,
    slot,
    phoneNumber,
    answeringNumberId,
    dncsSessionId,
    accountUuid,
  }: ScheduleSlotParams): Promise<ScheduleResponse> {
    return this.httpClient
      .post<ScheduleResponse>(
        `${environment.backendURL}/schedule`,
        {
          context,
          timezone,
          locale,
          slot: {
            start: slot.start && slot.start.toISOString(),
            end: slot.end && slot.end.toISOString(),
          },
          phoneNumber,
          answeringNumberId,
          dncsSessionId,
          accountUuid,
        },
        {
          headers: {
            'X-FS-APP-ID': appId,
          },
        }
      )
      .toPromise();
  }

  private slotsFromResponse = (response: SlotsResponse): Slots => ({
    allSlots: response.data.allSlots.map(this.toSlotsDay),
    suggestedSlots: response.data.suggestedSlots.map(this.toSlotsDay),
    timezone: response.data.timezone,
  })

  private toSlotsDay = (response: SlotsDayResponsePart): SlotsDay => ({
    label: response.displayString,
    subLabel: response.dateDisplayString,
    slots: response.timeSlots
      .filter(slot => slot.available)
      .map(slot => this.toSlot(slot, response.dateDisplayString)),
  })

  private toSlot = (
    response: SlotResponsePart,
    dateDisplayString: string
  ): Slot => ({
    start: new Date(response.start),
    end: new Date(response.end),
    label: response.displayText,
    dateLabel: dateDisplayString,
    available: response.available,
  })
}

import { Colors } from '@freespee/ui';
import { css } from 'emotion';

import AnimationTimes, {
  longAnimation,
  shortAnimation,
} from '#app/animation-times';

export const host = css({
  display: 'block',
  height: '100%',
  overflow: 'scroll',
});

export const moduleCards = css({
  padding: 16,
});

export const moduleCard = (show: boolean, cardNumber: number) =>
  css({
    marginTop: cardNumber > 1 ? 16 : 0,
    backgroundColor: Colors.WHITE,
    opacity: show ? 1 : 0,
    transform: show ? 'translateX(0)' : 'translateX(-100%)',
    transition: `all ${longAnimation}ms ease-in-out ${
      cardNumber * shortAnimation +
      AnimationTimes.module.deselect.step1.duration
    }ms`,
  });

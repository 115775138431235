import { NgModule } from '@angular/core';

import { BackendConfigService } from './backend-config.service';
import { ConfigService } from './config.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [BackendConfigService, ConfigService],
  exports: [],
})
export class ConfigModule {}

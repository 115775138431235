import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Colors } from '@freespee/ui';

import { ConnectModule } from '#gql';
import * as css from './app.styles';
import { BackendConfigService } from './config/backend-config.service';
import {
  Configuration,
  ConfigurationUpdate,
  StoredConfiguration,
} from './config/config.interface';
import { ConfigService } from './config/config.service';
import * as connectInternalMessaging from './internal-messaging/internal-messaging';
import { HeaderType } from './main/components/header/header.interface';
import { SchedulingView } from './scheduling/scheduling.interface';
import { getSessionIdFromCookie } from './util/dncs.helpers';
import { getErrorTexts } from './util/error-text.util';
import { getLocale, getTimezone } from './util/locale.util';

type ViewName = 'module-selector' | 'schedule-callback';

@Component({
  selector: 'app-root',
  template: `
    <app-header
      [header]="headerConfig.header"
      [text]="headerConfig.text"
      [fullscreenMode]="fullscreenMode"
      (back)="onSelected(undefined)"
      [headerType]="headerConfig.type"
      [textColor]="
        configuration &&
        configuration.colors &&
        configuration.colors.base.invertedText
          ? Colors.WHITE
          : Colors.MIDNIGHT_BLACK
      "
      [backgroundColor]="
        configuration && configuration.colors && configuration.colors.base.color
      "
    ></app-header>

    <div [class]="css.content" *ngIf="appId">
      <app-module-selector
        [class]="css.moduleSelector(shouldShowModuleSelector())"
        (selected)="onSelected($event)"
        [showCards]="shouldShowModuleSelector()"
        [configuration]="configuration"
      ></app-module-selector>

      <app-scheduling
        [class]="css.schedulingModule(module === ConnectModule.SCHEDULE)"
        [view]="schedulingView"
        [module]="module"
        (viewChanged)="schedulingView = $event"
        [configuration]="configuration"
        [appId]="appId"
        [dncsSessionId]="dncsSessionId"
        [answeringNumberId]="scheduleAnsweringNumberId"
        [accountUuid]="accountUuid"
      ></app-scheduling>
    </div>
    <app-notification
      *ngIf="error"
      [header]="error.header"
      [message]="error.message"
      (close)="error = undefined"
    ></app-notification>
    <app-footer></app-footer>
  `,
})
export class AppComponent implements OnInit {
  ConnectModule = ConnectModule;
  module: ConnectModule = undefined;
  HeaderType = HeaderType;
  Colors = Colors;
  css = css;
  fullscreenMode = false;
  schedulingView = SchedulingView.selectingSlot;
  configuration: Configuration;
  appId: string;
  accountUuid: string;
  dncsSessionId: string;
  scheduleAnsweringNumberId: string;
  usingClientSideTextOverride = false;

  error?: {
    header: string;
    message: string;
  };

  constructor(
    private backendConfigService: BackendConfigService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.configuration = this.configService.get();
  }

  @HostBinding('class')
  hostClass = css.host;

  headerConfig: { header: string; text: string; type: HeaderType } = {
    header: '',
    text: '',
    type: HeaderType.MAIN,
  };

  ngOnInit() {
    connectInternalMessaging.setupMessageListeners({
      onFullscreenModeChanged: this.toggleFullscreen.bind(this),
      onUpdateConfig: this.receivedClientSideConfigurationUpdate.bind(this),
      onShowView: this.onShowView.bind(this),
    });
    connectInternalMessaging.connectInitiated();

    this.route.queryParams.subscribe(params => {
      if (params.appId) {
        this.appId = params.appId;
        this.accountUuid = params.accountUuid;
        this.scheduleAnsweringNumberId = params.scheduleAnsweringNumberId;
        this.dncsSessionId = getSessionIdFromCookie();
        this.fetchConfiguration();
      }
    });
  }

  onShowView(view: string) {
    const moduleSelector = 'module-selector';
    const scheduleCallback = 'schedule-callback';
    const validViews: ViewName[] = [moduleSelector, scheduleCallback];
    if (!validViews.find(validView => validView === view)) {
      console.warn(
        `Freespee Connect: Invalid view, cannot display "${view}". Valid values are ${validViews.join(
          ', '
        )}`
      );
      return;
    }
    if (view === scheduleCallback) {
      this.onSelected(ConnectModule.SCHEDULE);
    } else if (view === moduleSelector) {
      this.onSelected(undefined);
    }
  }

  fetchConfiguration() {
    this.backendConfigService
      .getConfig({
        appId: this.appId,
        context: '{}',
        timezone: getTimezone(),
        locale: getLocale(),
      })
      .then(settings => {
        this.receivedConfigurationUpdateFromBackend(settings);
      })
      .catch(resp => {
        this.error = getErrorTexts(
          this.configuration.texts,
          resp?.error?.meta?.errorCode
        );
      });
  }

  onSelected(module?: ConnectModule) {
    if (module === undefined) {
      this.schedulingView = SchedulingView.selectingSlot;
    }
    this.module = module;
    this.setHeaderConfig();
  }

  shouldShowModuleSelector() {
    return this.module === undefined;
  }

  setHeaderConfig() {
    switch (this.module) {
      case ConnectModule.SCHEDULE:
        this.headerConfig = {
          type: HeaderType.MODULE,
          header: this.configuration.texts['freespeeConnectModuleScheduleTitle'],
          text: this.configuration.texts[
            'freespeeConnectModuleSelectionScheduleDescription'
          ],
        };
        break;
      default:
        this.headerConfig = {
          header: `${this.configuration.texts[
            'freespeeConnectModuleSelectionTitle'
          ].replace('%companyName%', this.configuration.companyName || '')}`,
          text: this.configuration.texts['freespeeConnectModuleSelectionSubTitle'],
          type: HeaderType.MAIN,
        };
    }
  }

  toggleFullscreen(fullscreen: boolean) {
    this.fullscreenMode = fullscreen;
  }

  receivedClientSideConfigurationUpdate(config: ConfigurationUpdate) {
    if (config.texts) {
      this.usingClientSideTextOverride = true;
    }
    this.updateConfig(config);
  }

  receivedConfigurationUpdateFromBackend(config: StoredConfiguration) {
    const update = {
      ...config,
    };
    // only use texts from backend if not using client side text overrides
    if (this.usingClientSideTextOverride) {
      delete update.texts;
    }

    this.updateConfig(update);
  }

  updateConfig(config: ConfigurationUpdate) {
    this.configService.update(config);
    this.configuration = this.configService.get();
    connectInternalMessaging.afterConfigUpdated(this.configuration);
    this.setHeaderConfig();
    this.cdr.detectChanges();
  }
}

import { Colors } from '@freespee/ui';
import { css } from 'emotion';

import { LARGE_MARGIN } from '#app/app.styles';

export const host = css({
  height: '100%',
  weight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: LARGE_MARGIN,
  backgroundColor: Colors.BACKGROUND,
  textAlign: 'center',
  overflow: 'scroll',

  'fs-icon svg path': {
    strokeWidth: '3px',
  },

  '& p': {
    marginBottom: 26,
  },
});

export const confirmIcon = css({
  marginBottom: 48,
});

export const textContainer = (show: boolean) =>
  css({
    opacity: show ? 1 : 0,
  });

import { Colors, FontFamily, getFont } from '@freespee/ui';
import { css } from 'emotion';

import AnimationTimes, {
  longAnimation,
  shortAnimation,
} from '../../../animation-times';
import { LARGE_MARGIN } from '../../../app.styles';
import { HeaderState, HeaderType } from './header.interface';

export const textFadeDuration = 100;

const bigHeaderHeight = 166;
const smallHeaderHeight = 104;

const bigHeader = (headerState: HeaderState) =>
  headerState === HeaderState.LARGE || headerState === HeaderState.GROWING;

const getMarginBottom = headerState =>
  headerState === HeaderState.SHRINKING || headerState === HeaderState.GROWING
    ? bigHeaderHeight - smallHeaderHeight
    : 0;

export const host = (
  headerState: HeaderState,
  backgroundColor: string = Colors.FREESPEE_BLUE
) => {
  // if we have a big header then the header animations are part of the first step of the select sequence
  // if we have a small header then the header animations are part of the second step of that deselect sequence
  const animationTiming = bigHeader(headerState)
    ? AnimationTimes.module.deselect.step2.timing
    : AnimationTimes.module.select.step1.timing;

  return css({
    backgroundColor,
    boxSizing: 'border-box',
    display: 'flex',
    flexShrink: 0,
    maxHeight: bigHeader(headerState) ? 166 : 104,
    height: bigHeader(headerState) ? 166 : 104,
    marginBottom: getMarginBottom(headerState),
    padding: bigHeader(headerState)
      ? `48px ${LARGE_MARGIN}px 30px `
      : `29px ${LARGE_MARGIN}px 17px ${LARGE_MARGIN}px`,
    '& fs-icon': {
      cursor: 'pointer',
      marginTop: 2,
    },
    transition: `
      height ${animationTiming},
      max-height ${animationTiming},
      margin-bottom ${animationTiming},
      padding ${shortAnimation}ms ease-in-out ${shortAnimation}ms
    `,
  });
};
export const leftColumn = (headerType: HeaderType) =>
  css({
    opacity: headerType === HeaderType.MAIN ? 0 : 1,
    width: headerType === HeaderType.MAIN ? 0 : LARGE_MARGIN,
    transition: `
      width ${shortAnimation}ms ease-in-out ${shortAnimation}ms,
      opacity ${longAnimation}ms ease-in-out ${shortAnimation}ms
    `,
  });

export const header = (headerType: HeaderType, color: string) =>
  css({
    ...getFont(FontFamily.HEADING),

    color,
    fontWeight: 'normal',
    ...(headerType === HeaderType.MAIN
      ? {
          fontSize: 28,
          letterSpacing: 0.7,
          marginBottom: 12,
        }
      : { fontSize: 16, letterSpacing: 0.4, marginBottom: 6 }),
  });

export const text = (headerType: HeaderType, color: string) =>
  css({
    ...getFont(FontFamily.BODY),

    color,
    fontWeight: 'normal',

    ...(headerType === HeaderType.MAIN
      ? {
          fontSize: 16,
          letterSpacing: 0.4,
          lineHeight: 1.31,
        }
      : {
          letterSpacing: 0.5,
          lineHeight: 1.5,
          fontSize: 12,
        }),
  });

export const closeIcon = css({
  position: 'absolute',
  top: 20,
  right: 20,
});

import { ConnectTexts } from '#gql';

export const defaultTexts: ConnectTexts = {
  freespeeConnectModuleSelectionTitle: '',
  freespeeConnectModuleSelectionSubTitle: '',
  freespeeConnectModuleSelectionScheduleDescription: '',
  freespeeConnectModuleSelectionMessageDescription: '',
  freespeeConnectModuleMessageTitle: '',
  freespeeConnectModuleScheduleTitle: '',
  freespeeConnectModuleScheduleSlotSelectionTitle: '',
  freespeeConnectModuleScheduleSlotSelectionSubTitle: '',
  freespeeConnectModuleScheduleSlotSelectionSuggestionTitle: '',
  freespeeConnectModuleScheduleSlotSelectionMore: '',
  freespeeConnectModuleScheduleSlotSelectionEmpty: '',
  freespeeConnectModuleSchedulePhoneNumberInputTitle: '',
  freespeeConnectModuleSchedulePhoneNumberInputTitleBold: '',
  freespeeConnectModuleSchedulePhoneNumberInputPlaceHolder: '',
  freespeeConnectModuleSchedulePhoneNumberInputScheduleButton: '',
  freespeeConnectModuleSchedulePhoneNumberInputChangeButton: '',
  freespeeConnectModuleScheduleDoneTitle: '',
  freespeeConnectModuleScheduleTryAgain: '',
  freespeeConnectAlertDismiss: '',
  freespeeConnectErrorsGenericTitle: 'An error occured',
  freespeeConnectErrorsGenericMessage:
    'Something went wrong, please try again.',
  freespeeConnectErrorsConnectivityTitle: 'No Connection',
  freespeeConnectErrorsConnectivityMessage:
    'There seems to be a problem with your network connection. Please try again later.',
  freespeeConnectErrorsValidationPhoneNumberMessage:
    'The phone number is invalid',
  freespeeConnectErrorsScheduleSlotMessage:
    'Something went wrong when trying to schedule your slot. Please try again.',
};

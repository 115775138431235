import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { commonStyles } from '#app/app.styles';
import { Configuration } from '#app/config/config.interface';
import { Slot, SlotsDay } from '#app/scheduling/scheduling.interface';
import { ConnectModule } from '#gql';
import { NumberConfirmedEvent } from '../phone-number-input/phone-number-input.interface';
import { SlotSchedulingEvent } from './slot-selector.interface';
import * as css from './slot-selector.styles';
import { SlotClicked } from './slot/slot.interface';

export enum View {
  SHOW_ALL,
  SHOW_SUGGESTED,
}
enum AnimationState {
  NOT_STARTED,
  SHOW,
  SLIDING_UP,
  REACHED_TOP,
  SHOW_CHANGE_TEXT,
}
@Component({
  selector: 'app-slot-selector',
  template: `
    <div [class]="css.slotLists(showSlotLists)">
      <div
        #suggestedSlotsRef
        data-cy="suggested slots"
        [class]="css.suggestedSlots"
      >
        <div *ngIf="suggestedSlots.length > 0">
          <app-slot-selector-message
            [configuration]="configuration"
          ></app-slot-selector-message>
          <div *ngFor="let day of suggestedSlots">
            <app-slot
              *ngFor="let slot of day.slots"
              [slot]="slot"
              [slotTimezone]="slotTimezone"
              (select)="onSlotSelected($event)"
            ></app-slot>
          </div>
        </div>
        <div *ngIf="suggestedSlots.length === 0">
          <app-slot-selector-message
            [configuration]="configuration"
          ></app-slot-selector-message>
          <div [class]="css.cardLoading">
            <fs-skeleton-card-small></fs-skeleton-card-small>
          </div>
        </div>
        <app-more-slots-button
          *ngIf="allSlots.length > 0"
          [show]="view === View.SHOW_SUGGESTED"
          (click)="scrollToTarget(View.SHOW_ALL)"
          [text]="
            configuration.texts[
              'freespeeConnectModuleScheduleSlotSelectionMore'
            ]
          "
        ></app-more-slots-button>
      </div>

      <div
        #allSlotsRef
        data-cy="all slots"
        [class]="css.allSlots"
        *ngIf="allSlots.length > 0"
      >
        <div data-cy="day" *ngFor="let day of allSlots">
          <div data-cy="header" [class]="css.slotGroupHeader">
            <h3>{{ day.label }}</h3>
            <span>{{ day.subLabel }}</span>
          </div>
          <div *ngIf="day.slots.length > 0">
            <app-slot
              *ngFor="let slot of day.slots"
              [inactivateAnimations]="true"
              [slot]="slot"
              [slotTimezone]="slotTimezone"
              (select)="onSlotSelected($event)"
            ></app-slot>
          </div>
          <app-empty-day
            *ngIf="day.slots.length === 0"
            [text]="
              configuration.texts[
                'freespeeConnectModuleScheduleSlotSelectionEmpty'
              ]
            "
          ></app-empty-day>
        </div>
      </div>
    </div>

    <div *ngIf="selected" [class]="css.selectedSlotContainer">
      <app-slot
        data-cy="selected slot"
        [slot]="selected.slot"
        (select)="onSlotDeselected()"
        [action]="getActionText()"
        [slotTimezone]="slotTimezone"
        [selected]="selected.animationState >= AnimationState.SLIDING_UP"
        [selectionCompleted]="
          selected.animationState === AnimationState.REACHED_TOP
        "
        [class]="
          css.selectedSlot(selected.initialOffsetY, selected.scrollTop) +
          (selected.animationState !== AnimationState.NOT_STARTED
            ? ' slideUp'
            : ' slideDown')
        "
      ></app-slot>

      <div
        [class]="
          css.phoneNumberInputContainer(selected.scrollTop) +
          (selected.animationState !== AnimationState.NOT_STARTED
            ? ' slideUp'
            : ' slideDown')
        "
      >
        <app-phone-number-form
          [slot]="selected.slot"
          (confirm)="onSlotConfirmed($event, selected.slot)"
          [configuration]="configuration"
        ></app-phone-number-form>
      </div>
    </div>
  `,
})
export class SlotSelectorComponent implements OnChanges {
  @HostBinding('class')
  get hostClass() {
    const allowScroll = this.selected === undefined;
    return css.host(allowScroll);
  }

  constructor(private schedulerRef: ElementRef) {}
  css = css;
  commonStyles = commonStyles;
  View = View;
  AnimationState = AnimationState;

  @ViewChild('suggestedSlotsRef') suggestedSlotsRef: ElementRef;
  @ViewChild('allSlotsRef') allSlotsRef: ElementRef;

  @Input()
  suggestedSlots: SlotsDay[] = [];

  @Input()
  allSlots: SlotsDay[] = [];

  @Input()
  slotTimezone: string;

  @Input()
  configuration: Configuration;

  @Input()
  module: ConnectModule;

  @Output()
  slotBooked = new EventEmitter<SlotSchedulingEvent>();

  selected: {
    slot: Slot;
    initialOffsetY: number;
    scrollTop: number;
    animationState: AnimationState;
  };
  showSlotLists = true;
  hitScrollToSuggestedBreakPointAt: number = undefined;

  view: View = View.SHOW_SUGGESTED;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.module &&
      changes.module.currentValue !== ConnectModule.SCHEDULE
    ) {
      // remove selected and scrolling to top if the schedule module is being closed
      this.scrollToSuggested();
      this.onSlotDeselected();
    }
  }

  scrollToTarget = (targetView: View) => {
    const scrollElement = this.elRef();
    const animationDuration = 300;
    const targetScrollTop =
      targetView === View.SHOW_SUGGESTED ? 0 : this.suggestedSlotsHeight();
    const distance = targetScrollTop - scrollElement.scrollTop;
    const startPosition = scrollElement.scrollTop;
    const startTime = Date.now();
    const setScroll = () => {
      const elapsed = Date.now() - startTime;
      const fractionCompleted = elapsed / animationDuration;
      if (fractionCompleted >= 1) {
        this.view = targetView;
        scrollElement.scrollTop = targetScrollTop;
        this.lockScrollAtFor(targetScrollTop, 400);
      } else {
        scrollElement.scrollTop = startPosition + fractionCompleted * distance;
        requestAnimationFrame(setScroll);
      }
    };
    requestAnimationFrame(setScroll);
  }

  private suggestedSlotsHeight = () =>
    this.suggestedSlotsRef.nativeElement.offsetHeight
  private getScrollToAllBreakPoint = () =>
    Math.max(0, this.suggestedSlotsHeight() - this.elRef().offsetHeight)
  private getScrollToSuggestedBreakPoint = () => this.suggestedSlotsHeight();

  private elRef = () => this.schedulerRef.nativeElement;
  private scrollTop = () => this.elRef().scrollTop;

  isScrollingToAllSlots = () =>
    this.view === View.SHOW_SUGGESTED &&
    this.scrollTop() > this.getScrollToAllBreakPoint()

  isScrollingToSuggested = () =>
    this.view === View.SHOW_ALL &&
    this.scrollTop() < this.getScrollToSuggestedBreakPoint()

  private scrollToSuggested() {
    this.hitScrollToSuggestedBreakPointAt = undefined;
    this.scrollToTarget(View.SHOW_SUGGESTED);
  }

  private lockScrollAtFor(scrollTop: number, milliseconds: number) {
    this.elRef().scrollTop = scrollTop;
    setTimeout(() => {
      this.elRef().scrollTop = scrollTop;
    }, milliseconds);
  }
  onSlotSelected({ event, slot }: SlotClicked) {
    const target = event.currentTarget as HTMLElement;
    this.selected = {
      slot,
      initialOffsetY: target.offsetTop,
      scrollTop: this.scrollTop(),
      animationState: AnimationState.NOT_STARTED,
    };
    this.showSlotLists = false;

    // 1. Set it to show a moment later to trigger the transition css effect
    // 2. Set it to update the state to SLIDING UP after the animation delay
    // 3. Set it to update the state to REACHED_TOP after the animation
    // 4. Set it to update the state to SHOW_CHANGE_TEXT some time after to display the change text
    this.delayedUpdateSelected(AnimationState.SHOW, 0)
      .then(
        this.delayedUpdateSelected.bind(
          this,
          AnimationState.SLIDING_UP,
          css.fadeDuration
        )
      )
      .then(
        this.delayedUpdateSelected.bind(
          this,
          AnimationState.REACHED_TOP,
          css.selectSlideDuration
        )
      )
      .then(
        this.delayedUpdateSelected.bind(
          this,
          AnimationState.SHOW_CHANGE_TEXT,
          1000
        )
      );
  }

  onSlotDeselected() {
    this.delayedUpdateSelected(
      AnimationState.NOT_STARTED,
      css.deselectSlideDelay
    );
    // Show slot list after deselect animation
    setTimeout(() => {
      this.showSlotLists = true;
    }, css.deselectSlideDuration);

    // Hide selected card after deselect animation
    setTimeout(() => {
      this.selected = undefined;
    }, css.fadeDuration + css.deselectSlideDuration);
  }

  onSlotConfirmed(event: NumberConfirmedEvent, slot: Slot) {
    this.slotBooked.emit({
      slot,
      phoneNumber: event.phoneNumber,
    });
  }

  getActionText = () =>
    this.selected &&
    this.selected.animationState === AnimationState.SHOW_CHANGE_TEXT
      ? this.configuration.texts[
          'freespeeConnectModuleSchedulePhoneNumberInputChangeButton'
        ]
      : undefined

  private delayedUpdateSelected(
    animationState: AnimationState,
    delay: number
  ): Promise<void> {
    const fromState = this.selected && this.selected.animationState;
    return new Promise(resolve => {
      setTimeout(() => {
        if (this.selected && fromState === this.selected.animationState) {
          this.selected = {
            ...this.selected,
            animationState,
          };
        }
        resolve();
      }, delay);
    });
  }
}

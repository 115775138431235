import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { SmallCardType } from '@freespee/angular';

import { Configuration } from '#app/config/config.interface';
import { ConnectModule } from '#gql';
import * as css from './module-selector.styles';

@Component({
  selector: 'app-module-selector',
  template: `
    <div [class]="css.moduleCards">
      <app-call-module-card
        *ngIf="configuration.modules.includes(ConnectModule.CALL)"
        [class]="css.moduleCard(showCards, 1)"
        [header]="configuration.companyName || ''"
        body="Call us! We typically pick up within 2 minutes"
        [statusCircleColor]="
          configuration &&
          configuration.colors &&
          configuration.colors.action.color
        "
        [statusImageUrl]="
          configuration &&
          configuration.images &&
          configuration.images.organisationLogoUrl
        "
      >
      </app-call-module-card>

      <fs-small-card
        data-cy="schedule call module"
        *ngIf="configuration.modules.includes(ConnectModule.SCHEDULE)"
        [class]="css.moduleCard(showCards, 2)"
        icon="calendar"
        [type]="SmallCardType.FORWARD"
        [borderless]="true"
        [title]="configuration.texts['freespeeConnectModuleScheduleTitle']"
        [body]="
          configuration.texts[
            'freespeeConnectModuleSelectionScheduleDescription'
          ]
        "
        (click)="selected.emit(ConnectModule.SCHEDULE)"
      >
      </fs-small-card>
    </div>
  `,
})
export class ModuleSelectorComponent {
  ConnectModule = ConnectModule;
  css = css;
  SmallCardType = SmallCardType;

  @Input()
  configuration: Configuration;

  constructor() {}

  @HostBinding('class')
  class = css.host;

  @Output()
  selected = new EventEmitter<ConnectModule>();

  @Input()
  showCards = false;
}

import { Colors, FontFamily, getFont } from '@freespee/ui';
import { css } from 'emotion';

import AnimationTimes from './animation-times';

export const FOOTER_HEIGHT = 32;
export const SMALL_MARGIN = 16;
export const LARGE_MARGIN = 40;

export const host = css({
  backgroundColor: Colors.WHITE,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  height: '100vh',
  width: '100vw',
});

export const content = css({
  height: `calc(100% - ${FOOTER_HEIGHT}px)`,
  overflow: 'scroll',
  display: 'flex',
  width: '200%',
  backgroundColor: Colors.BACKGROUND,
});

export const moduleSelector = (show: boolean) =>
  css({
    width: '100%',
    transition: show
      ? `all ${AnimationTimes.module.deselect.step2.timing}`
      : `all ${AnimationTimes.module.select.step1.timing}`,
    opacity: show ? 1 : 0,
    transform: show ? 'translateX(0)' : 'translateX(-125%)',
  });

export const schedulingModule = (show: boolean) =>
  css({
    opacity: show ? 1 : 0,
    width: '100%',
    transition: show
      ? `all ${AnimationTimes.module.select.step2.timing}`
      : `all ${AnimationTimes.module.deselect.step1.timing}`,

    transform: show ? 'translateX(-100%)' : 'translateX(0)',
  });

export const header = css({
  ...getFont(FontFamily.HEADING),

  color: Colors.MIDNIGHT_BLACK,
  fontSize: 20,
  fontWeight: 'normal',
  letterSpacing: 0.5,
  margin: '0 0 12px 0',
  padding: 0,
});

export const paragraph = css({
  ...getFont(FontFamily.BODY),

  color: Colors.MIDNIGHT_BLACK,
  fontSize: 16,
  fontWeight: 'normal',
  letterSpacing: 0.4,
  margin: `0 0 ${SMALL_MARGIN}px 0`,
  padding: 0,
  lineHeight: 1.31,
});

export const commonStyles = {
  header,
  paragraph,
};

export enum TrackingEventType {
  OPEN = 'open',
  CLOSE = 'close',
  SCHEDULE_SUCCEEDED = 'scheduleSucceeded',
  SCHEDULE_FAILED = 'scheduleFailed',
}

export interface TrackingEvent {
  sender: 'freespee-connect';
  type: TrackingEventType;
  payload: any;
}

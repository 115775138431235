import { ConnectTexts } from '#gql';

export const getErrorTexts = (texts: ConnectTexts, errorCode: string) => ({
  header: getErrorHeader(texts, errorCode),
  message: getErrorMessage(texts, errorCode),
});

export const getErrorHeader = (texts: ConnectTexts, errorCode: string) =>
  texts[errorCode + 'Title']
    ? texts[errorCode + 'Title']
    : texts['freespeeConnectErrorsGenericTitle'];

export const getErrorMessage = (texts: ConnectTexts, errorCode: string) =>
  texts[errorCode + 'Message']
    ? texts[errorCode + 'Message']
    : texts['freespeeConnectErrorsGenericMessage'];

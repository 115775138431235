import {
  ConnectColors,
  ConnectImages,
  ConnectModule,
  ConnectTexts,
} from '#gql';

export enum Mode {
  default = 'default',
  test = 'test',
}

export interface StoredConfiguration {
  defaultCountryCode: string;
  companyName: string;
  colors: ConnectColors;
  texts: ConnectTexts;
  modules: ConnectModule[];
  images: ConnectImages;
}

export interface Configuration extends StoredConfiguration {
  mode: Mode;
  closeOnBlur: boolean;
}

export type ConfigurationUpdate = Partial<Configuration>;

import { Colors } from '@freespee/ui';
import { css } from 'emotion';

import { SMALL_MARGIN } from '#app/app.styles';

export const host = css({
  height: '100%',
  width: '100%',
  backgroundColor: Colors.BACKGROUND,
  '& form': {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export const confirmButton = (colors?: {
  backgroundColor: string;
  textColor: string;
}) =>
  css({
    backgroundColor: colors
      ? `${colors.backgroundColor} !important`
      : undefined,
    color: colors ? `${colors.textColor} !important` : undefined,
    width: '100%',
    marginTop: SMALL_MARGIN,
  });

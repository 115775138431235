import { Colors, FontFamily, FontWeight, getFont } from '@freespee/ui';
import { css } from 'emotion';

export const host = css({
  display: 'flex',
  position: 'fixed',
  padding: '20px 40px',
  backgroundColor: Colors.NO_RED,
  justifyContent: 'space-between',
  alignItems: 'center',
  bottom: 0,
  left: 0,
  right: 0,
});

const textStyles = {
  ...getFont(FontFamily.BODY),
  color: Colors.WHITE,
  lineHeight: 1,
  margin: 0,
};
export const header = css({
  ...textStyles,
  fontSize: 14,
  fontWeight: FontWeight.SEMI_BOLD,
  letterSpacing: 0.3,
  marginBottom: 5,
});

export const message = css({
  ...textStyles,
  fontSize: 12,
  fontWeight: FontWeight.BOOK,
  letterSpacing: 0.5,
});

import { FontWeight } from '@freespee/ui';
import { css } from 'emotion';

import { LARGE_MARGIN, SMALL_MARGIN } from '#app/app.styles';
import {
  deselectSlideDuration,
  selectSlideDuration,
} from '../slot-selector.styles';

export const host = (selected: boolean) =>
  css({
    margin: `0 ${SMALL_MARGIN}px 8px ${SMALL_MARGIN}px`,
    display: 'block',

    '& fs-small-card:focus': {
      outline: 'none',
    },
    '& fs-small-card': {
      '& h2': {
        fontWeight: FontWeight.BOOK,
      },
      '& > *': {
        transition: selected
          ? `all ${selectSlideDuration}ms linear`
          : `all ${deselectSlideDuration}ms linear`,
      },
      ...(selected
        ? {
            '& > *:first-child': {
              marginLeft: LARGE_MARGIN,
            },
            '& > *:last-child': {
              marginRight: LARGE_MARGIN,
            },
          }
        : {}),
    },

    '& p': {
      lineHeight: 1,
    },
  });

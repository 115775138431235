import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { findTimeZone, getZonedTime } from 'timezone-support';

import { getTimezone } from '../../../../util/locale.util';
import { Slot } from '../../../scheduling.interface';
import { SlotClicked } from './slot.interface';
import * as css from './slot.styles';

@Component({
  selector: 'app-slot',
  template: `
    <fs-small-card
      [inactivateAnimations]="inactivateAnimations"
      (click)="onClick($event, slot)"
      [title]="slot.label"
      [titleSuffix]="timezoneText"
      icon="calendar"
      [type]="getType()"
      [borderless]="true"
      [completed]="selectionCompleted"
      [body]="slot.dateLabel"
      [action]="action"
    >
    </fs-small-card>
  `,
})
export class SlotComponent implements OnInit {
  @HostBinding('class')
  get class() {
    return css.host(this.selected);
  }
  css = css;
  timezoneText = '';

  @Input()
  inactivateAnimations = false;

  @Input()
  slot: Slot;

  @Input()
  action: string = undefined;

  @Input()
  selected = false;

  @Input()
  selectionCompleted = false;

  @Input()
  slotTimezone: string;

  @Output()
  select = new EventEmitter<SlotClicked>();

  ngOnInit() {
    const localTimezone = findTimeZone(getTimezone());
    const slotsInTimezone = findTimeZone(this.slotTimezone);
    const localTime = getZonedTime(new Date(), localTimezone);
    const slotScheduleTime = getZonedTime(new Date(), slotsInTimezone);
    const sameOffset = localTime.zone.offset === slotScheduleTime.zone.offset;
    if (!sameOffset) {
      this.timezoneText = slotScheduleTime.zone.abbreviation;
    }
  }

  onClick = (event: MouseEvent, slot: Slot) => {
    this.select.emit({ event, slot });
  }

  getType = () => (this.action ? 'default' : 'select');
}

import { css } from 'emotion';

import { LARGE_MARGIN } from '#app/app.styles';

export const host = (show: boolean) =>
  css({
    alignSelf: 'center',
    margin: LARGE_MARGIN,
    transition: 'opacity 200ms',
    opacity: show ? 1 : 0,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& fs-icon': {
      transform: 'rotate(90deg)',
    },
  });

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `UUID` scalar type represents UUID values as specified by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** Returns a UUID string, but for certain cases related to user access might return the string 'RESTRICTED' */
  RestrictedUuid: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { [key: string]: any };
  /** Is either a string or an integer */
  IntString: string | number;
  /** Is either a string, integer or a boolean */
  IntStringBoolean: string | number | boolean;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
};

export type Query = {
  __typename?: 'Query';
  accountSearch?: Maybe<AccountSearchPayload>;
  accounts: Accounts;
  accountByUUID?: Maybe<Account>;
  aggregatedStats: AggregatedStats;
  apiKeys: Array<ApiKey>;
  apiKey: ApiKey;
  connectApplications: Array<ConnectApplication>;
  connectApplication: ConnectApplicationPayload;
  connectScheduledCalls: ConnectScheduledCallsPayload;
  dashboard: Dashboard;
  accountSummaries?: Maybe<AccountSummaries>;
  callRecordingCategories?: Maybe<Array<Scalars['String']>>;
  calls: Calls;
  geolocations?: Maybe<Array<Scalars['String']>>;
  mediums?: Maybe<Array<Scalars['String']>>;
  datastudioTemplates?: Maybe<Array<Maybe<Template>>>;
  emailTemplates: Array<EmailTemplate>;
  previewEmailTemplate: Scalars['String'];
  legacyCredentialsVerify: Scalars['Boolean'];
  legacyCustomerSessionData?: Maybe<LegacyCustomerSessionData>;
  legacyAccessRecords: Array<AccessRecord>;
  legacySubCustomerSessionData?: Maybe<LegacySubCustomerSessionData>;
  legacyUserSessionData?: Maybe<LegacyUserSessionData>;
  legacyAvailableReportsSessionData: Array<LegacyAvailableReportsSessionData>;
  legacyScopesForEmail: Array<Scalars['String']>;
  legacyUserExists: Scalars['Boolean'];
  measureCustomFieldDefinitions?: Maybe<Array<Maybe<MeasureCustomFieldDefinition>>>;
  measureDynamicFieldDefinitions: Array<Maybe<Scalars['String']>>;
  loadMsdkApplication: MsdkApplication;
  loadAllMsdkApplications: Array<MsdkApplication>;
  organisations: Array<Organisation>;
  organisation?: Maybe<Organisation>;
  reportConfiguration?: Maybe<ReportConfiguration>;
  reportEmailsPerAccount: Array<ReportEmailsPerAccount>;
  quoteData?: Maybe<QuoteData>;
  savedQuotes: Array<Maybe<QuoteStore>>;
  savedUserQuotes: Array<Maybe<QuoteStore>>;
  loadRuleset?: Maybe<Ruleset>;
  rulesetTemplate?: Maybe<RulesetTemplate>;
  isUnsubscribed: Scalars['Boolean'];
  talkAgent: TalkAgent;
  talkAgents: Array<TalkAgent>;
  entityMetrics: Array<EntityStatistics>;
  entityMetricsByTimestamps: Array<EntityStatistics>;
  metrics: MetricObject;
  metricsByTimestamp: MetricObject;
  metricsByTimestamps: Array<MetricObject>;
  talkQueues: Array<TalkQueue>;
  states: Array<StateObject>;
  statesByTimestamp: Array<StateObject>;
  statesForAccounts: Array<AgentState>;
  statesByTimestampForAccounts: Array<AgentState>;
  userAccessRecords: GetAccessRecordsPayload;
  userAccessRecordsInOrganisation: GetAccessRecordsPayload;
  userByEmail: UserPayload;
  userById: UserPayload;
  userCredentialsVerify: Scalars['Boolean'];
  userRoles: GetUserRolesPayload;
  usersInOrganisation: Array<User>;
  usersSearchByEmail: Array<User>;
  usersWithOrganisationLevelAccessInOrganisation: Array<User>;
  userRolesInOrganisation: GetUserRolesPayload;
  rolesForOrganisation: Array<Role>;
  roles: Array<Role>;
  workflowsCriteria: Array<WorkflowsCriteria>;
  workflowsMacros: Array<WorkflowMacros>;
  workflowTransactionSummaryForOrganisation: Scalars['JSONObject'];
  workflowTransactionsSummaryByWorkflowId: Scalars['JSONObject'];
  workflows: Array<Workflow>;
  workflow: Workflow;
  actionTypes: Array<ActionType>;
  eventTypes: Array<EventType>;
};


export type QueryaccountSearchArgs = {
  query: AccountSearchQuery;
  organisationId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryaccountsArgs = {
  organisationId: Scalars['Int'];
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  flags?: Maybe<Array<Maybe<FlagComparison>>>;
  name?: Maybe<StringArrayComparison>;
  description?: Maybe<StringArrayComparison>;
  nameOrDescription?: Maybe<StringArrayComparison>;
  uuid?: Maybe<StringArrayComparison>;
  sortBy?: Maybe<AccountsSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryaccountByUUIDArgs = {
  organisationId: Scalars['Int'];
  uuid: Scalars['UUID'];
};


export type QueryaggregatedStatsArgs = {
  organisationId: Scalars['Int'];
  interval: DateTimeInterval;
  accountIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryapiKeysArgs = {
  organisationId: Scalars['Int'];
};


export type QueryapiKeyArgs = {
  id: Scalars['ID'];
};


export type QueryconnectApplicationsArgs = {
  organisationId: Scalars['Int'];
};


export type QueryconnectApplicationArgs = {
  id: Scalars['ID'];
};


export type QueryconnectScheduledCallsArgs = {
  organisationId: Scalars['Int'];
  accountId?: Maybe<Scalars['Int']>;
};


export type QuerydashboardArgs = {
  organisationId: Scalars['Int'];
  interval: DateTimeInterval;
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryaccountSummariesArgs = {
  organisationId: Scalars['Int'];
  interval: DateTimeInterval;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nameOrDescription?: Maybe<Scalars['String']>;
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sessions?: Maybe<IntComparison>;
  calls?: Maybe<IntComparison>;
  answeredCalls?: Maybe<IntComparison>;
  missedCalls?: Maybe<IntComparison>;
  qualityCalls?: Maybe<IntComparison>;
  droppedCalls?: Maybe<IntComparison>;
  staticCalls?: Maybe<IntComparison>;
  dynamicCalls?: Maybe<IntComparison>;
  uniqueCallers?: Maybe<IntComparison>;
  callThroughRate?: Maybe<FloatComparison>;
  answeredCallRate?: Maybe<FloatComparison>;
  missedCallRate?: Maybe<FloatComparison>;
  qualityCallRate?: Maybe<FloatComparison>;
  droppedCallRate?: Maybe<FloatComparison>;
  averageCallDuration?: Maybe<FloatComparison>;
  recoverySmsSent?: Maybe<IntComparison>;
  feedbackSmsSent?: Maybe<IntComparison>;
  feedbackReceived?: Maybe<IntComparison>;
  feedbackReceivedRate?: Maybe<FloatComparison>;
  averageFeedbackRate?: Maybe<FloatComparison>;
  recoveredCalls?: Maybe<IntComparison>;
  requestedCallbacks?: Maybe<IntComparison>;
  recoveredCallRate?: Maybe<FloatComparison>;
  callbackPerformanceRate?: Maybe<FloatComparison>;
  callbackRequestRate?: Maybe<FloatComparison>;
  conversions?: Maybe<IntComparison>;
  conversionValue?: Maybe<FloatComparison>;
  averageConversionValue?: Maybe<FloatComparison>;
  sortBy?: Maybe<AccountSummariesSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerycallRecordingCategoriesArgs = {
  organisationId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  sortBy?: Maybe<CallRecordingCategoriesSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerycallsArgs = {
  organisationId: Scalars['Int'];
  accountIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  start?: Maybe<DateTimeComparison>;
  status?: Maybe<Array<Maybe<CallStatus>>>;
  callType?: Maybe<Array<Maybe<CallType>>>;
  duration?: Maybe<IntComparison>;
  value?: Maybe<IntComparison>;
  geolocation?: Maybe<StringArrayComparison>;
  openingHours?: Maybe<Array<Maybe<OpeningHours>>>;
  callbackRequested?: Maybe<Scalars['Boolean']>;
  recoverySmsSent?: Maybe<Scalars['Boolean']>;
  feedbackSmsSent?: Maybe<Scalars['Boolean']>;
  numberType?: Maybe<Array<Maybe<NumberType>>>;
  browsingDevice?: Maybe<Array<Maybe<DeviceType>>>;
  medium?: Maybe<Array<Maybe<Scalars['String']>>>;
  urlLanding?: Maybe<StringArrayComparison>;
  urlLastVisited?: Maybe<StringArrayComparison>;
  urlReferral?: Maybe<StringArrayComparison>;
  recording?: Maybe<Scalars['Boolean']>;
  recordingCallCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy?: Maybe<CallsSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerygeolocationsArgs = {
  organisationId: Scalars['Int'];
  location?: Maybe<StringArrayComparison>;
  sortBy?: Maybe<GeolocationsSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerymediumsArgs = {
  organisationId: Scalars['Int'];
  name?: Maybe<StringArrayComparison>;
  sortBy?: Maybe<MediumsSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerydatastudioTemplatesArgs = {
  organisationId: Scalars['Int'];
};


export type QueryemailTemplatesArgs = {
  organisationId: Scalars['Int'];
};


export type QuerypreviewEmailTemplateArgs = {
  organisationId: Scalars['Int'];
  templateName: Scalars['String'];
  variables?: Maybe<Scalars['JSONObject']>;
};


export type QuerylegacyCredentialsVerifyArgs = {
  input: LegacyCredentialsVerifyInput;
};


export type QuerylegacyCustomerSessionDataArgs = {
  organisationId: Scalars['Int'];
};


export type QuerylegacyAccessRecordsArgs = {
  email: Scalars['String'];
  organisationId?: Maybe<Scalars['Int']>;
};


export type QuerylegacySubCustomerSessionDataArgs = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
};


export type QuerylegacyUserSessionDataArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
};


export type QuerylegacyAvailableReportsSessionDataArgs = {
  organisationId: Scalars['Int'];
};


export type QuerylegacyScopesForEmailArgs = {
  email: Scalars['String'];
};


export type QuerylegacyUserExistsArgs = {
  email: Scalars['String'];
};


export type QuerymeasureCustomFieldDefinitionsArgs = {
  organisationId: Scalars['Int'];
};


export type QuerymeasureDynamicFieldDefinitionsArgs = {
  organisationId: Scalars['Int'];
};


export type QueryloadMsdkApplicationArgs = {
  id: Scalars['String'];
};


export type QueryloadAllMsdkApplicationsArgs = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
};


export type QueryorganisationArgs = {
  id: Scalars['Int'];
};


export type QueryreportConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryreportEmailsPerAccountArgs = {
  reportId: Scalars['String'];
};


export type QueryquoteDataArgs = {
  currency?: Maybe<Scalars['String']>;
};


export type QueryloadRulesetArgs = {
  numberHash: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
};


export type QueryrulesetTemplateArgs = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  version?: Maybe<Scalars['Int']>;
};


export type QueryisUnsubscribedArgs = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  phoneNumber: Scalars['String'];
  type: SmsType;
};


export type QuerytalkAgentArgs = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  agentId: Scalars['Int'];
};


export type QuerytalkAgentsArgs = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  queueId: Scalars['Int'];
};


export type QueryentityMetricsArgs = {
  entityKeys: Array<EntityKey>;
};


export type QueryentityMetricsByTimestampsArgs = {
  entityKeys: Array<EntityKey>;
  timestamps: Array<Maybe<Scalars['String']>>;
};


export type QuerymetricsArgs = {
  host: Scalars['String'];
  endpoint: Scalars['String'];
};


export type QuerymetricsByTimestampArgs = {
  host: Scalars['String'];
  endpoint: Scalars['String'];
  timestamp?: Maybe<Scalars['String']>;
};


export type QuerymetricsByTimestampsArgs = {
  host: Scalars['String'];
  endpoint: Scalars['String'];
  timestamps: Array<Maybe<Scalars['String']>>;
};


export type QuerytalkQueuesArgs = {
  organisationId: Scalars['Int'];
  accountIds: Array<Scalars['Int']>;
};


export type QuerystatesArgs = {
  host: Scalars['String'];
  endpoint: Scalars['String'];
};


export type QuerystatesByTimestampArgs = {
  host: Scalars['String'];
  endpoint: Scalars['String'];
  timestamp: Scalars['String'];
};


export type QuerystatesForAccountsArgs = {
  accountIds: Array<Scalars['Int']>;
};


export type QuerystatesByTimestampForAccountsArgs = {
  accountIds: Array<Scalars['Int']>;
  timestamp: Scalars['String'];
};


export type QueryuserAccessRecordsArgs = {
  email: Scalars['String'];
};


export type QueryuserAccessRecordsInOrganisationArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
};


export type QueryuserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryuserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryuserCredentialsVerifyArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QueryuserRolesArgs = {
  email: Scalars['String'];
};


export type QueryusersInOrganisationArgs = {
  organisationId: Scalars['Int'];
  accountIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryusersSearchByEmailArgs = {
  email: Scalars['String'];
};


export type QueryusersWithOrganisationLevelAccessInOrganisationArgs = {
  organisationId: Scalars['Int'];
};


export type QueryuserRolesInOrganisationArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
};


export type QueryrolesForOrganisationArgs = {
  organisationId: Scalars['Int'];
};


export type QueryworkflowsCriteriaArgs = {
  organisationId: Scalars['Int'];
};


export type QueryworkflowsMacrosArgs = {
  organisationId: Scalars['Int'];
};


export type QueryworkflowTransactionSummaryForOrganisationArgs = {
  organisationId: Scalars['Int'];
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};


export type QueryworkflowTransactionsSummaryByWorkflowIdArgs = {
  organisationId: Scalars['Int'];
  workflowIds: Array<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};


export type QueryworkflowsArgs = {
  organisationId: Scalars['Int'];
  accountIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryworkflowArgs = {
  id: Scalars['UUID'];
};

export type AccountSearchQuery = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type AccountSearchPayload = {
  __typename?: 'AccountSearchPayload';
  records?: Maybe<Array<Account>>;
  errors?: Maybe<Array<AccountSearchProblem>>;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  organisationId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  isSuspended: Scalars['Boolean'];
  isTerminated: Scalars['Boolean'];
};

export type AccountSearchProblem = AccountSearchQueryNotSetProblem | AccountSearchQueryTooShortProblem;

export type AccountSearchQueryNotSetProblem = ProblemInterface & {
  __typename?: 'AccountSearchQueryNotSetProblem';
  message: Scalars['String'];
};

export type ProblemInterface = {
  message: Scalars['String'];
};

export type AccountSearchQueryTooShortProblem = ProblemInterface & {
  __typename?: 'AccountSearchQueryTooShortProblem';
  message: Scalars['String'];
};

export type FlagComparison = {
  flag: Scalars['Int'];
  status: FlagStatus;
};

export enum FlagStatus {
  SET = 'SET',
  NOT_SET = 'NOT_SET'
}

export type StringArrayComparison = {
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  comparator: Comparator;
};

export enum Comparator {
  EQUAL = 'EQUAL',
  LIKE = 'LIKE',
  NOT = 'NOT',
  NOT_LIKE = 'NOT_LIKE',
  NULL = 'NULL',
  NOT_NULL = 'NOT_NULL',
  NAME_OR_DESCRIPTION = 'NAME_OR_DESCRIPTION'
}

export type AccountsSortBy = {
  field?: Maybe<AccountsSortFields>;
  order?: Maybe<SortOrder>;
};

export enum AccountsSortFields {
  id = 'id',
  name = 'name'
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Accounts = {
  __typename?: 'Accounts';
  data: Array<Account>;
  count?: Maybe<Scalars['Int']>;
};


export type DateTimeInterval = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AggregatedStats = {
  __typename?: 'AggregatedStats';
  totalCalls: Scalars['Int'];
  totalMissedCalls: Scalars['Int'];
  callsPerAdPriceRange: Array<CallsPerAdPriceRange>;
  topAdsByCalls: Array<Ad>;
  dateHourWithMostAnsweredCalls?: Maybe<DateTimeWithValue>;
  dateHourWithMostMissedCalls?: Maybe<DateTimeWithValue>;
};


export type AggregatedStatscallsPerAdPriceRangeArgs = {
  ranges: Array<PriceRangeInput>;
};


export type AggregatedStatstopAdsByCallsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type PriceRangeInput = {
  from: Scalars['Int'];
  to?: Maybe<Scalars['Int']>;
};

export type CallsPerAdPriceRange = {
  __typename?: 'CallsPerAdPriceRange';
  range: PriceRange;
  missedCalls: Scalars['Int'];
  answeredCalls: Scalars['Int'];
};

export type PriceRange = {
  __typename?: 'PriceRange';
  from: Scalars['Int'];
  to?: Maybe<Scalars['Int']>;
};

export type Ad = {
  __typename?: 'Ad';
  id: Scalars['String'];
  numberOfCalls: Scalars['Int'];
  title: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type DateTimeWithValue = {
  __typename?: 'DateTimeWithValue';
  datetime: Scalars['DateTime'];
  value: Scalars['Int'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  env_org: Scalars['String'];
  environmentId: Scalars['Int'];
  organisationId: Scalars['Int'];
  accountIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  referenceId: Scalars['String'];
  scopes: Array<Scalars['String']>;
};

export type ConnectApplication = {
  __typename?: 'ConnectApplication';
  config: ConnectApplicationConfig;
  id: Scalars['String'];
  environmentId: Scalars['Int'];
  organisationId: Scalars['Int'];
  disabled: Scalars['Boolean'];
  createdAt: Scalars['Float'];
  updatedAt: Scalars['Float'];
};

export type ConnectApplicationConfig = {
  __typename?: 'ConnectApplicationConfig';
  defaultLocale: Scalars['String'];
  localesInUse: Array<Scalars['String']>;
  companyName: Scalars['String'];
  colors: ConnectColors;
  images: ConnectImages;
  textOverrides: Array<ConnectTextsPerLocale>;
  scheduling: ConnectSchedulingConfig;
  openingHours: ConnectOpeningHours;
  modules: Array<ConnectModule>;
};

export type ConnectColors = {
  __typename?: 'ConnectColors';
  action: ConnectColor;
  base: ConnectColor;
};

export type ConnectColor = {
  __typename?: 'ConnectColor';
  color: Scalars['String'];
  invertedText: Scalars['Boolean'];
};

export type ConnectImages = {
  __typename?: 'ConnectImages';
  organisationLogoUrl?: Maybe<Scalars['String']>;
};

export type ConnectTextsPerLocale = {
  __typename?: 'ConnectTextsPerLocale';
  locale: Scalars['String'];
  texts: ConnectTexts;
};

export type ConnectTexts = {
  __typename?: 'ConnectTexts';
  freespeeConnectModuleSelectionTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleSelectionSubTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleSelectionScheduleDescription?: Maybe<Scalars['String']>;
  freespeeConnectModuleSelectionMessageDescription?: Maybe<Scalars['String']>;
  freespeeConnectModuleMessageTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionSubTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionSuggestionTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionMore?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionEmpty?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputTitleBold?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputPlaceHolder?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputScheduleButton?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputChangeButton?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleDoneTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleTryAgain?: Maybe<Scalars['String']>;
  freespeeConnectAlertDismiss?: Maybe<Scalars['String']>;
  freespeeConnectErrorsGenericTitle?: Maybe<Scalars['String']>;
  freespeeConnectErrorsGenericMessage?: Maybe<Scalars['String']>;
  freespeeConnectErrorsConnectivityTitle?: Maybe<Scalars['String']>;
  freespeeConnectErrorsConnectivityMessage?: Maybe<Scalars['String']>;
  freespeeConnectErrorsValidationPhoneNumberMessage?: Maybe<Scalars['String']>;
  freespeeConnectErrorsScheduleSlotMessage?: Maybe<Scalars['String']>;
};

export type ConnectSchedulingConfig = {
  __typename?: 'ConnectSchedulingConfig';
  slots: ConnectSlotsConfig;
};

export type ConnectSlotsConfig = {
  __typename?: 'ConnectSlotsConfig';
  lengthInMinutes: Scalars['Int'];
  simultaneousCallbackLimit: Scalars['Int'];
  daysAheadLimit: Scalars['Int'];
};

export type ConnectOpeningHours = {
  __typename?: 'ConnectOpeningHours';
  timezone: Scalars['String'];
  weekdays: ConnectOpeningHoursWeekdays;
  exceptions: Array<ConnectOpeningHourException>;
};

export type ConnectOpeningHoursWeekdays = {
  __typename?: 'ConnectOpeningHoursWeekdays';
  monday: ConnectDayOpeningHours;
  tuesday: ConnectDayOpeningHours;
  wednesday: ConnectDayOpeningHours;
  thursday: ConnectDayOpeningHours;
  friday: ConnectDayOpeningHours;
  saturday: ConnectDayOpeningHours;
  sunday: ConnectDayOpeningHours;
};

export type ConnectDayOpeningHours = {
  __typename?: 'ConnectDayOpeningHours';
  closedAllDay: Scalars['Boolean'];
  hours: Array<ConnectOpenHour>;
};

export type ConnectOpenHour = {
  __typename?: 'ConnectOpenHour';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type ConnectOpeningHourException = {
  __typename?: 'ConnectOpeningHourException';
  type: ConnectOpeningHourExceptionType;
  date: Scalars['String'];
  hours: Array<ConnectOpenHour>;
};

export enum ConnectOpeningHourExceptionType {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export enum ConnectModule {
  CALL = 'CALL',
  SCHEDULE = 'SCHEDULE'
}

export type ConnectApplicationPayload = {
  __typename?: 'ConnectApplicationPayload';
  record?: Maybe<ConnectApplication>;
  errors?: Maybe<Array<ConnectApplicationProblem>>;
};

export type ConnectApplicationProblem = ConnectApplicationDoesNotExistProblem;

export type ConnectApplicationDoesNotExistProblem = ProblemInterface & {
  __typename?: 'ConnectApplicationDoesNotExistProblem';
  message: Scalars['String'];
};

export type ConnectScheduledCallsPayload = {
  __typename?: 'ConnectScheduledCallsPayload';
  records: Array<ConnectScheduledCall>;
};

export type ConnectScheduledCall = {
  __typename?: 'ConnectScheduledCall';
  accountId: Scalars['Int'];
  agentPhoneNumber?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  created: Scalars['DateTime'];
  customerPhoneNumber: Scalars['String'];
  retries: Scalars['Int'];
  retryInterval: Scalars['Int'];
  scheduledTime?: Maybe<Scalars['DateTime']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  callOverview: CallOverview;
  additionalData: AdditionalData;
};

export type CallOverview = {
  __typename?: 'CallOverview';
  calls: Array<ChartDataPoint>;
  qualityCalls: Array<ChartDataPoint>;
  sessions: Array<ChartDataPoint>;
};

export type ChartDataPoint = {
  __typename?: 'ChartDataPoint';
  yValue: Scalars['Int'];
  xValue: Scalars['DateTime'];
};

export type AdditionalData = {
  __typename?: 'AdditionalData';
  callsPerDayAverage?: Maybe<Scalars['Float']>;
  qualityCallsPerDayAverage?: Maybe<Scalars['Float']>;
  sessionsPerDayAverage?: Maybe<Scalars['Float']>;
};

export type IntComparison = {
  moreThan?: Maybe<Scalars['Int']>;
  moreOrEquals?: Maybe<Scalars['Int']>;
  lessThan?: Maybe<Scalars['Int']>;
  lessOrEquals?: Maybe<Scalars['Int']>;
  equals?: Maybe<Scalars['Int']>;
};

export type FloatComparison = {
  moreThan?: Maybe<Scalars['Float']>;
  moreOrEquals?: Maybe<Scalars['Float']>;
  lessThan?: Maybe<Scalars['Float']>;
  lessOrEquals?: Maybe<Scalars['Float']>;
  equals?: Maybe<Scalars['Float']>;
};

export type AccountSummariesSortBy = {
  field?: Maybe<AccountSummariesSortFields>;
  order?: Maybe<SortOrder>;
};

export enum AccountSummariesSortFields {
  id = 'id',
  name = 'name',
  description = 'description',
  sessions = 'sessions',
  calls = 'calls',
  answeredCalls = 'answeredCalls',
  missedCalls = 'missedCalls',
  qualityCalls = 'qualityCalls',
  droppedCalls = 'droppedCalls',
  staticCalls = 'staticCalls',
  dynamicCalls = 'dynamicCalls',
  uniqueCallers = 'uniqueCallers',
  callThroughRate = 'callThroughRate',
  answeredCallRate = 'answeredCallRate',
  missedCallRate = 'missedCallRate',
  qualityCallRate = 'qualityCallRate',
  droppedCallRate = 'droppedCallRate',
  averageCallDuration = 'averageCallDuration',
  recoverySmsSent = 'recoverySmsSent',
  feedbackSmsSent = 'feedbackSmsSent',
  feedbackReceived = 'feedbackReceived',
  feedbackReceivedRate = 'feedbackReceivedRate',
  averageFeedbackRate = 'averageFeedbackRate',
  recoveredCalls = 'recoveredCalls',
  recoveredCallRate = 'recoveredCallRate',
  requestedCallbacks = 'requestedCallbacks',
  callbackPerformanceRate = 'callbackPerformanceRate',
  callbackRequestRate = 'callbackRequestRate',
  conversions = 'conversions',
  conversionValue = 'conversionValue',
  averageConversionValue = 'averageConversionValue'
}

export type AccountSummaries = {
  __typename?: 'AccountSummaries';
  summary?: Maybe<AllAccountsSummary>;
  data?: Maybe<Array<Maybe<AccountSummary>>>;
  count?: Maybe<Scalars['Int']>;
};


export type AccountSummariessummaryArgs = {
  type?: Maybe<SummaryType>;
};

export enum SummaryType {
  AVERAGE = 'AVERAGE',
  AVERAGE_ALL = 'AVERAGE_ALL',
  SUM = 'SUM',
  SUM_ALL = 'SUM_ALL'
}

export type AllAccountsSummary = {
  __typename?: 'AllAccountsSummary';
  sessions?: Maybe<Scalars['Float']>;
  calls?: Maybe<Scalars['Float']>;
  answeredCalls?: Maybe<Scalars['Float']>;
  missedCalls?: Maybe<Scalars['Float']>;
  qualityCalls?: Maybe<Scalars['Float']>;
  droppedCalls?: Maybe<Scalars['Float']>;
  staticCalls?: Maybe<Scalars['Float']>;
  dynamicCalls?: Maybe<Scalars['Float']>;
  uniqueCallers?: Maybe<Scalars['Float']>;
  callThroughRate?: Maybe<Scalars['Float']>;
  answeredCallRate?: Maybe<Scalars['Float']>;
  missedCallRate?: Maybe<Scalars['Float']>;
  qualityCallRate?: Maybe<Scalars['Float']>;
  droppedCallRate?: Maybe<Scalars['Float']>;
  averageCallDuration?: Maybe<Scalars['Float']>;
  recoverySmsSent?: Maybe<Scalars['Float']>;
  feedbackSmsSent?: Maybe<Scalars['Float']>;
  feedbackReceived?: Maybe<Scalars['Float']>;
  feedbackReceivedRate?: Maybe<Scalars['Float']>;
  averageFeedbackRate?: Maybe<Scalars['Float']>;
  requestedCallbacks?: Maybe<Scalars['Float']>;
  recoveredCalls?: Maybe<Scalars['Float']>;
  recoveredCallRate?: Maybe<Scalars['Float']>;
  callbackPerformanceRate?: Maybe<Scalars['Float']>;
  callbackRequestRate?: Maybe<Scalars['Float']>;
  conversions?: Maybe<Scalars['Float']>;
  conversionValue?: Maybe<Scalars['Float']>;
  averageConversionValue?: Maybe<Scalars['Float']>;
};

export type AccountSummary = {
  __typename?: 'AccountSummary';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sessions?: Maybe<Scalars['Int']>;
  calls?: Maybe<Scalars['Int']>;
  answeredCalls?: Maybe<Scalars['Int']>;
  missedCalls?: Maybe<Scalars['Int']>;
  qualityCalls?: Maybe<Scalars['Int']>;
  droppedCalls?: Maybe<Scalars['Int']>;
  staticCalls?: Maybe<Scalars['Int']>;
  dynamicCalls?: Maybe<Scalars['Int']>;
  uniqueCallers?: Maybe<Scalars['Int']>;
  callThroughRate?: Maybe<Scalars['Float']>;
  answeredCallRate?: Maybe<Scalars['Float']>;
  missedCallRate?: Maybe<Scalars['Float']>;
  qualityCallRate?: Maybe<Scalars['Float']>;
  droppedCallRate?: Maybe<Scalars['Float']>;
  averageCallDuration?: Maybe<Scalars['Float']>;
  recoverySmsSent?: Maybe<Scalars['Int']>;
  feedbackSmsSent?: Maybe<Scalars['Int']>;
  feedbackReceived?: Maybe<Scalars['Int']>;
  feedbackReceivedRate?: Maybe<Scalars['Float']>;
  averageFeedbackRate?: Maybe<Scalars['Float']>;
  requestedCallbacks?: Maybe<Scalars['Int']>;
  recoveredCalls?: Maybe<Scalars['Int']>;
  recoveredCallRate?: Maybe<Scalars['Float']>;
  callbackPerformanceRate?: Maybe<Scalars['Float']>;
  callbackRequestRate?: Maybe<Scalars['Float']>;
  conversions?: Maybe<Scalars['Int']>;
  conversionValue?: Maybe<Scalars['Float']>;
  averageConversionValue?: Maybe<Scalars['Float']>;
};

export type CallRecordingCategoriesSortBy = {
  field: CallRecordingCategoriesSortFields;
  order?: Maybe<SortOrder>;
};

export enum CallRecordingCategoriesSortFields {
  frequency = 'frequency'
}

export type DateTimeComparison = {
  moreThan?: Maybe<Scalars['DateTime']>;
  moreOrEquals?: Maybe<Scalars['DateTime']>;
  lessThan?: Maybe<Scalars['DateTime']>;
  lessOrEquals?: Maybe<Scalars['DateTime']>;
  equals?: Maybe<Scalars['DateTime']>;
};

export enum CallStatus {
  MISSED_UNSUCCESSFUL = 'MISSED_UNSUCCESSFUL',
  ANSWERED = 'ANSWERED',
  INCOMPLETE = 'INCOMPLETE'
}

export enum CallType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND'
}

export enum OpeningHours {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
  NOT_CONFIGURED = 'NOT_CONFIGURED'
}

export enum NumberType {
  STATIC = 'STATIC',
  DYNAMIC_SERVER_SIDE = 'DYNAMIC_SERVER_SIDE',
  CLICK_TO_CALL = 'CLICK_TO_CALL',
  EXTERNAL = 'EXTERNAL',
  AUTHENTICATION = 'AUTHENTICATION',
  DYNAMIC_CLIENT_SIDE = 'DYNAMIC_CLIENT_SIDE',
  CALLBACK = 'CALLBACK',
  PIN_CALLBACK = 'PIN_CALLBACK'
}

export enum DeviceType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  UNKNOWN = 'UNKNOWN'
}

export type CallsSortBy = {
  field?: Maybe<CallsSortFields>;
  order?: Maybe<SortOrder>;
};

export enum CallsSortFields {
  id = 'id',
  accountId = 'accountId',
  accountName = 'accountName',
  accountNumber = 'accountNumber',
  callerId = 'callerId',
  freespeeNumber = 'freespeeNumber',
  receivingNumber = 'receivingNumber',
  geolocation = 'geolocation',
  duration = 'duration',
  start = 'start',
  end = 'end',
  status = 'status',
  callType = 'callType',
  value = 'value',
  currency = 'currency',
  openingHours = 'openingHours',
  callbackRequested = 'callbackRequested',
  recoverySmsSent = 'recoverySmsSent',
  medium = 'medium',
  numberType = 'numberType',
  utmTerm = 'utmTerm',
  keyword = 'keyword',
  referrer = 'referrer',
  googleCLID = 'googleCLID',
  urlLanding = 'urlLanding',
  urlLastVisited = 'urlLastVisited',
  urlReferral = 'urlReferral',
  recordingCallCategory = 'recordingCallCategory',
  campaignId = 'campaignId',
  campaignName = 'campaignName',
  feedbackRating = 'feedbackRating',
  feedbackResponse = 'feedbackResponse',
  feedbackSmsSent = 'feedbackSmsSent',
  answeringNumberName = 'answeringNumberName',
  answeringNumberIdentifier = 'answeringNumberIdentifier',
  googleCID = 'googleCID',
  browsingDevice = 'browsingDevice',
  recording = 'recording',
  routingSegmentName = 'routingSegmentName',
  customerSpecificExternalLeadId = 'customerSpecificExternalLeadId',
  customerSpecificCustomerName = 'customerSpecificCustomerName',
  customerSpecificCallStatus = 'customerSpecificCallStatus',
  customerSpecificBrand = 'customerSpecificBrand',
  customerSpecificSurrogateId = 'customerSpecificSurrogateId'
}

export type Calls = {
  __typename?: 'Calls';
  data?: Maybe<Array<Maybe<Call>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Call = {
  __typename?: 'Call';
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  accountNumber?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  callerId?: Maybe<Scalars['String']>;
  freespeeNumber?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  geolocation?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  start?: Maybe<Scalars['DateTime']>;
  startSimpleLocalTime?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  endSimpleLocalTime?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  callType?: Maybe<CallType>;
  value?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  openingHours: OpeningHours;
  callbackRequested: Scalars['Boolean'];
  recoverySmsSent: Scalars['Boolean'];
  medium?: Maybe<Scalars['String']>;
  numberType?: Maybe<NumberType>;
  utmTerm?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  googleCLID?: Maybe<Scalars['String']>;
  urlLanding?: Maybe<Scalars['String']>;
  urlLastVisited?: Maybe<Scalars['String']>;
  urlReferral?: Maybe<Scalars['String']>;
  recordingCallCategory?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Int']>;
  campaignName?: Maybe<Scalars['String']>;
  feedbackRating?: Maybe<Scalars['Float']>;
  feedbackResponse?: Maybe<Scalars['String']>;
  feedbackSmsSent: Scalars['Boolean'];
  answeringNumberName?: Maybe<Scalars['String']>;
  answeringNumberIdentifier?: Maybe<Scalars['String']>;
  googleCID?: Maybe<Scalars['String']>;
  browsingDevice: DeviceType;
  dynamicField?: Maybe<Scalars['String']>;
  customField?: Maybe<Scalars['String']>;
  recording?: Maybe<Scalars['RestrictedUuid']>;
  customerSpecificExternalLeadId?: Maybe<Scalars['String']>;
  customerSpecificCustomerName?: Maybe<Scalars['String']>;
  customerSpecificCallStatus?: Maybe<Scalars['Int']>;
  customerSpecificBrand?: Maybe<Scalars['String']>;
  customerSpecificSurrogateId?: Maybe<Scalars['Int']>;
  routingSegmentName?: Maybe<Scalars['String']>;
};


export type CalldynamicFieldArgs = {
  field: Scalars['String'];
};


export type CallcustomFieldArgs = {
  fieldId: Scalars['Int'];
  type?: Maybe<NumberType>;
};


export type GeolocationsSortBy = {
  field?: Maybe<GeolocationsSortFields>;
  order?: Maybe<SortOrder>;
};

export enum GeolocationsSortFields {
  frequency = 'frequency'
}

export type MediumsSortBy = {
  field?: Maybe<MediumsSortFields>;
  order?: Maybe<SortOrder>;
};

export enum MediumsSortFields {
  name = 'name',
  frequency = 'frequency'
}

export type Template = {
  __typename?: 'Template';
  uuid: Scalars['String'];
  template?: Maybe<Scalars['JSON']>;
};


export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  name: Scalars['String'];
  version: EmailTemplateVersion;
  variables: Array<Scalars['String']>;
};

export enum EmailTemplateVersion {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}


export type LegacyCredentialsVerifyInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LegacyCustomerSessionData = {
  __typename?: 'LegacyCustomerSessionData';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  legal_name?: Maybe<Scalars['String']>;
  global_email?: Maybe<Scalars['String']>;
  logo_gui?: Maybe<Scalars['String']>;
  logo_print?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  datetime_locale?: Maybe<Scalars['String']>;
  numbers_left_in_areacodes?: Maybe<Scalars['Int']>;
  allow_upcoming_measures?: Maybe<Scalars['Int']>;
  force_subcustnr_unique?: Maybe<Scalars['Int']>;
  quarantine_extension?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  flags?: Maybe<Scalars['Float']>;
  countrycode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  utf8encode?: Maybe<Scalars['Int']>;
  cfn_expire_request?: Maybe<Scalars['Int']>;
  cfn_expire_call?: Maybe<Scalars['Int']>;
  cfn_primary_ccndc?: Maybe<Scalars['String']>;
  cfn_secondary_ccndc?: Maybe<Scalars['String']>;
  custnr_validation?: Maybe<Scalars['String']>;
  callback_group_id?: Maybe<Scalars['Int']>;
  support_email?: Maybe<Scalars['String']>;
  support_phone?: Maybe<Scalars['String']>;
  vm_timeout?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  webnum_expire_request?: Maybe<Scalars['Int']>;
  webnum_expire_call?: Maybe<Scalars['Int']>;
  webnum_expire_wait?: Maybe<Scalars['Int']>;
  webnum_primary_ccndc?: Maybe<Scalars['String']>;
  webnum_secondary_ccndc?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  min_cost_per_lead?: Maybe<Scalars['Float']>;
  max_smallest_condition_duration?: Maybe<Scalars['Int']>;
  reserved_source_expire?: Maybe<Scalars['Int']>;
  max_call_recording_storage?: Maybe<Scalars['Int']>;
  vat_type?: Maybe<Scalars['String']>;
  master_dncs_default_subcust_id?: Maybe<Scalars['Int']>;
  number_cap?: Maybe<Scalars['Int']>;
  number_cap_alarm_threshold?: Maybe<Scalars['Int']>;
  allowed_numbers_quota_default?: Maybe<Scalars['Int']>;
  segments_lock?: Maybe<Scalars['Int']>;
  allowed_answering_numbers_quota?: Maybe<Scalars['Int']>;
  minimum_talk_version?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  php_date_format?: Maybe<Scalars['String']>;
  php_time_format?: Maybe<Scalars['String']>;
  php_datetime_format?: Maybe<Scalars['String']>;
  mysql_date_format?: Maybe<Scalars['String']>;
  mysql_time_format?: Maybe<Scalars['String']>;
  mysql_datetime_format?: Maybe<Scalars['String']>;
};

export type AccessRecord = {
  __typename?: 'AccessRecord';
  organisation: Organisation;
  account?: Maybe<Account>;
};

export type Organisation = {
  __typename?: 'Organisation';
  id: Scalars['Int'];
  name: Scalars['String'];
  flags: Scalars['Float'];
  isSuspended: Scalars['Boolean'];
  isTerminated: Scalars['Boolean'];
};

export type LegacySubCustomerSessionData = {
  __typename?: 'LegacySubCustomerSessionData';
  id: Scalars['Int'];
  cust_id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  custnr?: Maybe<Scalars['String']>;
  corporateid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  address_zip?: Maybe<Scalars['String']>;
  address_city?: Maybe<Scalars['String']>;
  address_state?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  business_segment_id?: Maybe<Scalars['Int']>;
  website_url?: Maybe<Scalars['String']>;
  custom_1?: Maybe<Scalars['String']>;
  custom_2?: Maybe<Scalars['String']>;
  custom_3?: Maybe<Scalars['String']>;
  custom_4?: Maybe<Scalars['String']>;
  custom_5?: Maybe<Scalars['String']>;
  custom_6?: Maybe<Scalars['String']>;
  custom_7?: Maybe<Scalars['String']>;
  custom_8?: Maybe<Scalars['String']>;
  custom_9?: Maybe<Scalars['String']>;
  custom_10?: Maybe<Scalars['String']>;
  custom_11?: Maybe<Scalars['String']>;
  custom_12?: Maybe<Scalars['String']>;
  custom_13?: Maybe<Scalars['String']>;
  custom_14?: Maybe<Scalars['String']>;
  custom_15?: Maybe<Scalars['String']>;
  custom_16?: Maybe<Scalars['String']>;
  custom_17?: Maybe<Scalars['String']>;
  custom_18?: Maybe<Scalars['String']>;
  custom_19?: Maybe<Scalars['String']>;
  custom_20?: Maybe<Scalars['String']>;
  flags?: Maybe<Scalars['Int']>;
  nextsourcename?: Maybe<Scalars['Int']>;
  nextdncsserial?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['String']>;
  api_password?: Maybe<Scalars['String']>;
  dncs_class_name?: Maybe<Scalars['String']>;
  webnum_expire_request?: Maybe<Scalars['Int']>;
  webnum_expire_call?: Maybe<Scalars['Int']>;
  webnum_expire_wait?: Maybe<Scalars['Int']>;
  default_campaign_id?: Maybe<Scalars['Int']>;
  callduration_balance?: Maybe<Scalars['Int']>;
  automatic_plan_upgrade?: Maybe<Scalars['Int']>;
  vat_number?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  vat_type?: Maybe<Scalars['String']>;
  plantype_version?: Maybe<Scalars['Int']>;
  number_cap?: Maybe<Scalars['Int']>;
  number_cap_alarm_threshold?: Maybe<Scalars['Int']>;
  allowed_answering_numbers_quota?: Maybe<Scalars['Int']>;
  countrycode?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  datetime_locale?: Maybe<Scalars['String']>;
  webnum_primary_ccndc?: Maybe<Scalars['String']>;
  webnum_secondary_ccndc?: Maybe<Scalars['String']>;
};

export type LegacyUserSessionData = {
  __typename?: 'LegacyUserSessionData';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  subcust: Scalars['Int'];
  cust_id: Scalars['Int'];
  flags: Scalars['Float'];
  failed_attempts: Scalars['Int'];
  login_flags: Scalars['Float'];
};

export type LegacyAvailableReportsSessionData = {
  __typename?: 'LegacyAvailableReportsSessionData';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type MeasureCustomFieldDefinition = {
  __typename?: 'MeasureCustomFieldDefinition';
  label: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  numberType?: Maybe<NumberType>;
};

export type MsdkApplication = {
  __typename?: 'MsdkApplication';
  id: Scalars['String'];
  name: Scalars['String'];
  envOrg: Scalars['String'];
  accountId: Scalars['Int'];
  fcm?: Maybe<Fcm>;
  apns?: Maybe<Apns>;
  sandbox: Scalars['Boolean'];
  toBeDeleted: Scalars['Boolean'];
  apiKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  updatedAt: Scalars['Float'];
};

export type Fcm = {
  __typename?: 'Fcm';
  key: Scalars['String'];
};

export type Apns = {
  __typename?: 'Apns';
  key: Scalars['String'];
  cert: Scalars['String'];
};

export type ReportConfiguration = {
  __typename?: 'ReportConfiguration';
  id: Scalars['String'];
  environmentId: Scalars['Int'];
  organisationId: Scalars['Int'];
  timezone: Scalars['String'];
  lookback: ReportLookback;
  dataPoints: Array<ReportDataPoint>;
  recipientsType: ReportRecipientsType;
  emailSettings: ReportEmailSettings;
};

export enum ReportLookback {
  LASTWEEK = 'LASTWEEK',
  LASTMONTH = 'LASTMONTH'
}

export type ReportDataPoint = {
  __typename?: 'ReportDataPoint';
  name: Scalars['String'];
  parameters?: Maybe<Scalars['JSON']>;
};

export enum ReportRecipientsType {
  ACCOUNT_EMAIL = 'ACCOUNT_EMAIL'
}

export type ReportEmailSettings = {
  __typename?: 'ReportEmailSettings';
  templateName: Scalars['String'];
  fromAddress: Scalars['String'];
  fromName: Scalars['String'];
  subject: Scalars['String'];
  locale: Scalars['String'];
  forceRealToEmail: Scalars['Boolean'];
};

export type ReportEmailsPerAccount = {
  __typename?: 'ReportEmailsPerAccount';
  accountId: Scalars['Int'];
  emails: Array<Scalars['String']>;
};

export type QuoteData = {
  __typename?: 'QuoteData';
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  creditMultiple?: Maybe<Scalars['Int']>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  usagePrices: Array<Maybe<UsagePrice>>;
};

export type Quote = {
  __typename?: 'Quote';
  type?: Maybe<QuoteType>;
  masterAccounts?: Maybe<Scalars['Int']>;
  routings: Scalars['Int'];
  pageViews?: Maybe<Scalars['Int']>;
  apiAccess: Scalars['Boolean'];
  integrations: Scalars['Int'];
  workflows: Scalars['Int'];
  usageCredits: Scalars['Int'];
  quotePrices: QuotePrices;
};

export enum QuoteType {
  REGULAR = 'REGULAR',
  MARKETPLACE = 'MARKETPLACE'
}

export type QuotePrices = {
  __typename?: 'QuotePrices';
  engage?: Maybe<LicensePrice>;
  talk?: Maybe<LicensePrice>;
  sdk?: Maybe<LicensePrice>;
  addOns?: Maybe<AddonPrice>;
};

export type LicensePrice = {
  __typename?: 'LicensePrice';
  prices?: Maybe<Array<Maybe<Price>>>;
  onboardingFee: Scalars['Int'];
  licenseFee: Scalars['Int'];
};

export type Price = {
  __typename?: 'Price';
  min: Scalars['Int'];
  max?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type AddonPrice = {
  __typename?: 'AddonPrice';
  workflows?: Maybe<Array<Maybe<Price>>>;
  integrations?: Maybe<Array<Maybe<Price>>>;
};

export type UsagePrice = {
  __typename?: 'UsagePrice';
  countryName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  localNumbers?: Maybe<Scalars['Float']>;
  tollFreeNumbers?: Maybe<Scalars['Float']>;
  receive?: Maybe<Scalars['Float']>;
  receiveTollFree?: Maybe<Scalars['Float']>;
  callLandline?: Maybe<Scalars['Float']>;
  callMobile?: Maybe<Scalars['Float']>;
  appCall?: Maybe<Scalars['Float']>;
  sms?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['Float']>;
  callTranscription?: Maybe<Scalars['Float']>;
};

export type QuoteStore = {
  __typename?: 'QuoteStore';
  id: Scalars['String'];
  createdAt: Scalars['Float'];
  updatedAt: Scalars['Float'];
  quoteStore?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
};

export type Ruleset = {
  __typename?: 'Ruleset';
  id: Scalars['String'];
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  version: Scalars['String'];
  latestVersion: Scalars['Int'];
  parameters: Scalars['String'];
  createdAt: Scalars['Float'];
};

export type RulesetTemplate = {
  __typename?: 'RulesetTemplate';
  id: Scalars['String'];
  version: Scalars['String'];
  latestVersion: Scalars['Int'];
  template: Scalars['String'];
  createdAt: Scalars['Float'];
};

export enum SmsType {
  OUTGOING_CUSTOMER_FEEDBACK = 'OUTGOING_CUSTOMER_FEEDBACK',
  OUTGOING_FOLLOWUP_SMS = 'OUTGOING_FOLLOWUP_SMS',
  ALL = 'ALL'
}

export type TalkAgent = {
  __typename?: 'TalkAgent';
  id: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  countryCode: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type EntityKey = {
  accountId: Scalars['Int'];
  entityId: Scalars['String'];
};

export type EntityStatistics = {
  __typename?: 'EntityStatistics';
  attemptedCallsOffered?: Maybe<Scalars['Int']>;
  callsWaiting?: Maybe<Scalars['Int']>;
  callsPreQueue?: Maybe<Scalars['Int']>;
  callsInQueue?: Maybe<Scalars['Int']>;
  callsInConversation?: Maybe<Scalars['Int']>;
  callsOutOfHours?: Maybe<Scalars['Int']>;
  callsAbandonedPreQueue?: Maybe<Scalars['Int']>;
  callsAbandonedInQueue?: Maybe<Scalars['Int']>;
  incomingCallsAnswered?: Maybe<Scalars['Int']>;
  incomingCallsMissed?: Maybe<Scalars['Int']>;
  ongoingCalls?: Maybe<Scalars['Int']>;
  totalOutgoingCalls?: Maybe<Scalars['Int']>;
  totalCalls?: Maybe<Scalars['Int']>;
  agentsLoggedIn?: Maybe<Scalars['Int']>;
  agentsAvailable?: Maybe<Scalars['Int']>;
  totalAgents?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  accountId: Scalars['Int'];
  entityId: Scalars['String'];
};

export type MetricObject = {
  __typename?: 'MetricObject';
  attemptedCallsOffered?: Maybe<Scalars['Int']>;
  callsWaiting?: Maybe<Scalars['Int']>;
  callsPreQueue?: Maybe<Scalars['Int']>;
  callsInQueue?: Maybe<Scalars['Int']>;
  callsInConversation?: Maybe<Scalars['Int']>;
  callsOutOfHours?: Maybe<Scalars['Int']>;
  callsAbandonedPreQueue?: Maybe<Scalars['Int']>;
  callsAbandonedInQueue?: Maybe<Scalars['Int']>;
  incomingCallsAnswered?: Maybe<Scalars['Int']>;
  incomingCallsMissed?: Maybe<Scalars['Int']>;
  ongoingCalls?: Maybe<Scalars['Int']>;
  totalOutgoingCalls?: Maybe<Scalars['Int']>;
  totalCalls?: Maybe<Scalars['Int']>;
  agentsLoggedIn?: Maybe<Scalars['Int']>;
  agentsAvailable?: Maybe<Scalars['Int']>;
  totalAgents?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  endpoint: Scalars['String'];
  host: Scalars['String'];
};

export type TalkQueue = {
  __typename?: 'TalkQueue';
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  name: Scalars['String'];
  accountId: Scalars['Int'];
  accountName: Scalars['String'];
  agents?: Maybe<Array<Maybe<TalkAgent>>>;
  agentSelectionMethod: Scalars['Int'];
  language: Scalars['String'];
  maxSize?: Maybe<Scalars['Int']>;
  maxWait?: Maybe<Scalars['Int']>;
};

export type StateObject = {
  __typename?: 'StateObject';
  status: Scalars['String'];
  state: Scalars['String'];
  endpoint: Scalars['String'];
  host: Scalars['String'];
  ts: Scalars['DateTime'];
};

export type AgentState = {
  __typename?: 'AgentState';
  status: Scalars['String'];
  state: Scalars['String'];
  timestamp: Scalars['DateTime'];
  accountId: Scalars['Int'];
  agentId: Scalars['String'];
};

export type GetAccessRecordsPayload = {
  __typename?: 'GetAccessRecordsPayload';
  records?: Maybe<Array<AccessRecord>>;
  errors?: Maybe<Array<GetAccessRecordsProblem>>;
};

export type GetAccessRecordsProblem = UserDoesNotExistProblem;

export type UserDoesNotExistProblem = ProblemInterface & {
  __typename?: 'UserDoesNotExistProblem';
  message: Scalars['String'];
};

export type UserPayload = {
  __typename?: 'UserPayload';
  record?: Maybe<User>;
  errors?: Maybe<Array<UserFindProblem>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  accountIds: Array<Scalars['Int']>;
  roles?: Maybe<Array<Role>>;
  hasOrganisationLevelAccess: Scalars['Boolean'];
};


export type UseraccountIdsArgs = {
  organisationId: Scalars['Int'];
};


export type UserrolesArgs = {
  organisationId: Scalars['Int'];
};


export type UserhasOrganisationLevelAccessArgs = {
  organisationId: Scalars['Int'];
};

export type Role = {
  __typename?: 'Role';
  organisationId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  locked: Scalars['Boolean'];
  scopes: Array<Scope>;
};

export type Scope = {
  __typename?: 'Scope';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UserFindProblem = UserDoesNotExistProblem | ResourceAccessProblem;

export type ResourceAccessProblem = ProblemInterface & {
  __typename?: 'ResourceAccessProblem';
  message: Scalars['String'];
};

export type GetUserRolesPayload = {
  __typename?: 'GetUserRolesPayload';
  records?: Maybe<Array<Role>>;
  errors?: Maybe<Array<GetUserRolesProblem>>;
};

export type GetUserRolesProblem = UserDoesNotExistProblem;

export type WorkflowsCriteria = {
  __typename?: 'WorkflowsCriteria';
  eventTypeId: Scalars['String'];
  criteria: Array<Criterion>;
};

export type Criterion = {
  __typename?: 'Criterion';
  property: Scalars['String'];
  comparators: Array<CriterionComparator>;
  type: WorkflowsCriterionType;
  field: FieldDefinition;
};

export enum CriterionComparator {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  IN_LIST = 'IN_LIST',
  NOT_IN_LIST = 'NOT_IN_LIST',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
  IS_EMPTY = 'IS_EMPTY'
}

export enum WorkflowsCriterionType {
  EVENT_PROPERTY = 'EVENT_PROPERTY',
  DYNAMIC_FIELD = 'DYNAMIC_FIELD',
  CUSTOM_FIELD = 'CUSTOM_FIELD'
}

export type FieldDefinition = {
  __typename?: 'FieldDefinition';
  name?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  type: FieldType;
  validation?: Maybe<Array<FieldValidator>>;
  placeholder?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['IntStringBoolean']>;
  options?: Maybe<Array<FieldOption>>;
  listFieldType?: Maybe<ListFieldDefinition>;
  enabledConditions?: Maybe<Scalars['JSONObject']>;
  fields?: Maybe<Array<FieldDefinition>>;
};

export enum FieldType {
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  COLOR = 'COLOR',
  DROPDOWN = 'DROPDOWN',
  EMAIL = 'EMAIL',
  EMAIL_TEMPLATE_PREVIEW = 'EMAIL_TEMPLATE_PREVIEW',
  FIELD_GROUP = 'FIELD_GROUP',
  LIST = 'LIST',
  NUMBER = 'NUMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  RADIO = 'RADIO',
  SMS_PREVIEW = 'SMS_PREVIEW',
  TEXT = 'TEXT',
  TEXT_LONG = 'TEXT_LONG',
  TEXT_LONG_MACRO = 'TEXT_LONG_MACRO',
  TEXT_MACRO = 'TEXT_MACRO',
  TIMEOUT = 'TIMEOUT',
  TOGGLE = 'TOGGLE',
  URL = 'URL'
}

export type FieldValidator = {
  __typename?: 'FieldValidator';
  type: FieldValidatorId;
  arguments: Array<Scalars['IntString']>;
};

export enum FieldValidatorId {
  REQUIRED = 'REQUIRED',
  MIN_LENGTH = 'MIN_LENGTH',
  MAX_LENGTH = 'MAX_LENGTH',
  MIN = 'MIN',
  MAX = 'MAX',
  REGEX = 'REGEX',
  NO_WHITESPACE = 'NO_WHITESPACE',
  NO_INITIAL_NUMBER = 'NO_INITIAL_NUMBER',
  ONLY_NUMBERS_OR_LETTERS = 'ONLY_NUMBERS_OR_LETTERS'
}



export type FieldOption = {
  __typename?: 'FieldOption';
  label: Scalars['String'];
  value: Scalars['IntString'];
};

export type ListFieldDefinition = {
  __typename?: 'ListFieldDefinition';
  type: FieldType;
  validation: Array<FieldValidator>;
  placeholder?: Maybe<Scalars['String']>;
};

export type WorkflowMacros = {
  __typename?: 'WorkflowMacros';
  eventTypeId: Scalars['String'];
  macros: Array<MacroDefinition>;
};

export type MacroDefinition = {
  __typename?: 'MacroDefinition';
  type: WorkflowsMacroType;
  label: Scalars['String'];
  property: Scalars['String'];
  exampleValue: Scalars['IntString'];
  description?: Maybe<Scalars['String']>;
};

export enum WorkflowsMacroType {
  EVENT_PROPERTY = 'EVENT_PROPERTY',
  DYNAMIC_FIELD = 'DYNAMIC_FIELD',
  CUSTOM_FIELD = 'CUSTOM_FIELD'
}

export type Workflow = {
  __typename?: 'Workflow';
  id: Scalars['UUID'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  environmentId: Scalars['Int'];
  organisationId: Scalars['Int'];
  enabledFor: EnabledFor;
  accountIds: Array<Scalars['Int']>;
  nodes: Array<WorkflowNode>;
  createdAt: Scalars['Float'];
  updatedAt: Scalars['Float'];
  rootEventType: EventTypeId;
};

export enum EnabledFor {
  ALL = 'ALL',
  ALL_BUT = 'ALL_BUT',
  NONE_BUT = 'NONE_BUT'
}

export type WorkflowNode = {
  __typename?: 'WorkflowNode';
  id: Scalars['UUID'];
  eventType: EventTypeId;
  parentNodeId?: Maybe<Scalars['UUID']>;
  criteria: Array<CriterionInstance>;
  actionType?: Maybe<ActionTypeId>;
  actionValues: Scalars['JSONObject'];
};

export enum EventTypeId {
  CALL_ENDS = 'CALL_ENDS',
  FEEDBACK_SMS_FEEDBACK_RECEIVED = 'FEEDBACK_SMS_FEEDBACK_RECEIVED',
  FEEDBACK_SMS_FEEDBACK_TIMEOUT = 'FEEDBACK_SMS_FEEDBACK_TIMEOUT'
}

export type CriterionInstance = {
  __typename?: 'CriterionInstance';
  property: Scalars['String'];
  comparator: CriterionComparator;
  value?: Maybe<Scalars['IntString']>;
};

export enum ActionTypeId {
  SMS = 'SMS',
  EMAIL = 'EMAIL'
}

export type ActionType = {
  __typename?: 'ActionType';
  id: ActionTypeId;
  label: Scalars['String'];
  configuration: ConfigurationBlueprint;
  emits: Array<EventTypeId>;
};

export type ConfigurationBlueprint = {
  __typename?: 'ConfigurationBlueprint';
  sections: Array<Section>;
};

export type Section = {
  __typename?: 'Section';
  label: Scalars['String'];
  fields: Array<FieldDefinition>;
};

export type EventType = {
  __typename?: 'EventType';
  id: EventTypeId;
  label: Scalars['String'];
  mustBeHandled: Scalars['Boolean'];
  rootEvent: Scalars['Boolean'];
  availableActions: Array<ActionTypeId>;
};

export type Mutation = {
  __typename?: 'Mutation';
  apiKeyCreate: CreateApiKeyResponse;
  updateCallSpecification: UpdateCallSpecificationResponse;
  connectCreateApplication: ConnectCreateApplicationPayload;
  connectUpdateApplication: ConnectUpdateApplicationPayload;
  connectDeleteApplication: ConnectDeleteApplicationPayload;
  addDatastudioTemplate: Template;
  deleteDatastudioTemplate: Scalars['Boolean'];
  legacyUpdatePasswordForUser: Scalars['Boolean'];
  createMsdkApplication: MsdkApplication;
  updateMsdkApplication: MsdkApplication;
  deleteMsdkApplication: Scalars['Boolean'];
  addSavedQuote: QuoteStore;
  updateSavedQuote: QuoteStore;
  deleteSavedQuote: Scalars['Boolean'];
  createRuleset: Ruleset;
  createRulesetTemplate: RulesetTemplate;
  unsubscribeFromSms: Scalars['Boolean'];
  grantAccountAccess: GrantAccountAccessPayload;
  revokeAccountAccess: RevokeAccountAccessPayload;
  grantOrganisationAccess: GrantOrganisationAccessPayload;
  revokeOrganisationAccess: RevokeOrganisationAccessPayload;
  legacySyncUserCreate: LegacySyncUserCreatePayload;
  legacySyncUserEdit: LegacySyncUserEditPayload;
  legacySyncUserRemove: LegacySyncUserRemovePayload;
  legacySyncLoginCreate: LegacySyncLoginCreatePayload;
  legacySyncLoginEdit: LegacySyncLoginEditPayload;
  userSetPassword: Scalars['Boolean'];
  userRoleAdd: SetUserRolesPayload;
  userRoleRemove: SetUserRolesPayload;
  userRolesRemoveAll: RemoveAllUserRolesPayload;
  userCreate: User;
  userEdit: UserEditPayload;
  roleLockForOrganisation: RoleLockPayload;
  roleUnlockForOrganisation: RoleUnlockPayload;
  roleRemoveForUsersInOrganisation: RoleRemoveForUsersInOrganisationPayload;
  deleteWorkflow?: Maybe<Scalars['Boolean']>;
  createWorkflow: WorkflowCreatePayload;
  updateWorkflow: WorkflowUpdatePayload;
};


export type MutationapiKeyCreateArgs = {
  key?: Maybe<ApiKeyInput>;
};


export type MutationupdateCallSpecificationArgs = {
  callId: Scalars['String'];
  callSpecification: CallSpecificationInput;
};


export type MutationconnectCreateApplicationArgs = {
  application: ConnectCreateApplicationInput;
};


export type MutationconnectUpdateApplicationArgs = {
  applicationId: Scalars['ID'];
  application: ConnectUpdateApplicationInput;
};


export type MutationconnectDeleteApplicationArgs = {
  applicationId: Scalars['ID'];
};


export type MutationaddDatastudioTemplateArgs = {
  organisationId: Scalars['Int'];
  template: Scalars['String'];
};


export type MutationdeleteDatastudioTemplateArgs = {
  organisationId: Scalars['Int'];
  uuid: Scalars['String'];
};


export type MutationlegacyUpdatePasswordForUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationcreateMsdkApplicationArgs = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  createParams: CreateMsdkApplicationParams;
};


export type MutationupdateMsdkApplicationArgs = {
  id: Scalars['String'];
  updateParams: UpdateMsdkApplicationParams;
};


export type MutationdeleteMsdkApplicationArgs = {
  id: Scalars['String'];
};


export type MutationaddSavedQuoteArgs = {
  quoteStore: Scalars['String'];
  countryCode: Scalars['String'];
  companyName: Scalars['String'];
};


export type MutationupdateSavedQuoteArgs = {
  id: Scalars['String'];
  quoteStore: Scalars['String'];
  countryCode: Scalars['String'];
  companyName: Scalars['String'];
};


export type MutationdeleteSavedQuoteArgs = {
  id: Scalars['String'];
};


export type MutationcreateRulesetArgs = {
  params: RulesetParams;
};


export type MutationcreateRulesetTemplateArgs = {
  params: RulesetTemplateParams;
};


export type MutationunsubscribeFromSmsArgs = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  phoneNumber: Scalars['String'];
  type: SmsType;
};


export type MutationgrantAccountAccessArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
};


export type MutationrevokeAccountAccessArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
};


export type MutationgrantOrganisationAccessArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
};


export type MutationrevokeOrganisationAccessArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
};


export type MutationlegacySyncUserCreateArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
  flags: Scalars['Int'];
  accountId?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
};


export type MutationlegacySyncUserEditArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
  input: LegacySyncUserEditInput;
  accountId?: Maybe<Scalars['Int']>;
};


export type MutationlegacySyncUserRemoveArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
  accountId?: Maybe<Scalars['Int']>;
};


export type MutationlegacySyncLoginCreateArgs = {
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationlegacySyncLoginEditArgs = {
  email: Scalars['String'];
  input: LegacySyncLoginEditInput;
};


export type MutationuserSetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationuserRoleAddArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
  roleId: Scalars['Int'];
};


export type MutationuserRoleRemoveArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
  roleId: Scalars['Int'];
};


export type MutationuserRolesRemoveAllArgs = {
  email: Scalars['String'];
  organisationId: Scalars['Int'];
};


export type MutationuserCreateArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};


export type MutationuserEditArgs = {
  email: Scalars['String'];
  input: UserEditInput;
};


export type MutationroleLockForOrganisationArgs = {
  organisationId: Scalars['Int'];
  roleId: Scalars['Int'];
};


export type MutationroleUnlockForOrganisationArgs = {
  organisationId: Scalars['Int'];
  roleId: Scalars['Int'];
};


export type MutationroleRemoveForUsersInOrganisationArgs = {
  organisationId: Scalars['Int'];
  roleId: Scalars['Int'];
};


export type MutationdeleteWorkflowArgs = {
  id: Scalars['UUID'];
};


export type MutationcreateWorkflowArgs = {
  input: WorkflowInput;
};


export type MutationupdateWorkflowArgs = {
  id: Scalars['UUID'];
  input: WorkflowInput;
};

export type ApiKeyInput = {
  organisationId: Scalars['Int'];
  accountIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  scopes: Array<Scalars['String']>;
};

export type CreateApiKeyResponse = {
  __typename?: 'CreateApiKeyResponse';
  record?: Maybe<ApiKey>;
  errors?: Maybe<Array<ApiKeyProblems>>;
};

export type ApiKeyProblems = ApiKeyCreateProblem | ApiKeyInvalidScopesProblem;

export type ApiKeyCreateProblem = ApiKeyProblemInterface & {
  __typename?: 'ApiKeyCreateProblem';
  message: Scalars['String'];
};

export type ApiKeyProblemInterface = {
  message: Scalars['String'];
};

export type ApiKeyInvalidScopesProblem = ApiKeyProblemInterface & {
  __typename?: 'ApiKeyInvalidScopesProblem';
  message: Scalars['String'];
};

export type CallSpecificationInput = {
  commission?: Maybe<Scalars['Float']>;
  customDynamic?: Maybe<Scalars['JSONObject']>;
};

export type UpdateCallSpecificationResponse = {
  __typename?: 'UpdateCallSpecificationResponse';
  record?: Maybe<CallSpecification>;
  errors?: Maybe<Array<UpdateCallSpecificationProblems>>;
};

export type CallSpecification = {
  __typename?: 'CallSpecification';
  id: Scalars['Int'];
  callId: Scalars['ID'];
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  commission?: Maybe<Scalars['Float']>;
  customDynamic?: Maybe<Scalars['JSONObject']>;
};

export type UpdateCallSpecificationProblems = CallSpecificationDoesNotExistProblem | CallSpecificationUpdateProblem | CallSpecificationAccessProblem;

export type CallSpecificationDoesNotExistProblem = ProblemInterface & {
  __typename?: 'CallSpecificationDoesNotExistProblem';
  message: Scalars['String'];
};

export type CallSpecificationUpdateProblem = ProblemInterface & {
  __typename?: 'CallSpecificationUpdateProblem';
  message: Scalars['String'];
};

export type CallSpecificationAccessProblem = ProblemInterface & {
  __typename?: 'CallSpecificationAccessProblem';
  message: Scalars['String'];
};

export type ConnectCreateApplicationInput = {
  config: ConnectApplicationConfigInput;
  organisationId: Scalars['Int'];
  disabled: Scalars['Boolean'];
};

export type ConnectApplicationConfigInput = {
  defaultLocale: Scalars['String'];
  localesInUse: Array<Scalars['String']>;
  companyName: Scalars['String'];
  colors: ConnectColorsInput;
  images: ConnectImagesInput;
  textOverrides: Array<ConnectTextsPerLocaleInput>;
  scheduling: ConnectSchedulingConfigInput;
  openingHours: ConnectOpeningHoursInput;
  modules: Array<ConnectModule>;
};

export type ConnectColorsInput = {
  action: ConnectColorInput;
  base: ConnectColorInput;
};

export type ConnectColorInput = {
  color: Scalars['String'];
  invertedText: Scalars['Boolean'];
};

export type ConnectImagesInput = {
  organisationLogoUrl?: Maybe<Scalars['String']>;
};

export type ConnectTextsPerLocaleInput = {
  locale: Scalars['String'];
  texts: ConnectTextsInput;
};

export type ConnectTextsInput = {
  freespeeConnectModuleSelectionTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleSelectionSubTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleSelectionScheduleDescription?: Maybe<Scalars['String']>;
  freespeeConnectModuleSelectionMessageDescription?: Maybe<Scalars['String']>;
  freespeeConnectModuleMessageTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionSubTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionSuggestionTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionMore?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleSlotSelectionEmpty?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputTitleBold?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputPlaceHolder?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputScheduleButton?: Maybe<Scalars['String']>;
  freespeeConnectModuleSchedulePhoneNumberInputChangeButton?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleDoneTitle?: Maybe<Scalars['String']>;
  freespeeConnectModuleScheduleTryAgain?: Maybe<Scalars['String']>;
  freespeeConnectAlertDismiss?: Maybe<Scalars['String']>;
  freespeeConnectErrorsGenericTitle?: Maybe<Scalars['String']>;
  freespeeConnectErrorsGenericMessage?: Maybe<Scalars['String']>;
  freespeeConnectErrorsConnectivityTitle?: Maybe<Scalars['String']>;
  freespeeConnectErrorsConnectivityMessage?: Maybe<Scalars['String']>;
  freespeeConnectErrorsValidationPhoneNumberMessage?: Maybe<Scalars['String']>;
  freespeeConnectErrorsScheduleSlotMessage?: Maybe<Scalars['String']>;
};

export type ConnectSchedulingConfigInput = {
  slots: ConnectSlotsConfigInput;
};

export type ConnectSlotsConfigInput = {
  lengthInMinutes: Scalars['Int'];
  simultaneousCallbackLimit: Scalars['Int'];
  daysAheadLimit: Scalars['Int'];
};

export type ConnectOpeningHoursInput = {
  timezone: Scalars['String'];
  weekdays: ConnectOpeningHoursWeekdaysInput;
  exceptions: Array<ConnectOpeningHourExceptionInput>;
};

export type ConnectOpeningHoursWeekdaysInput = {
  monday: ConnectDayOpeningHoursInput;
  tuesday: ConnectDayOpeningHoursInput;
  wednesday: ConnectDayOpeningHoursInput;
  thursday: ConnectDayOpeningHoursInput;
  friday: ConnectDayOpeningHoursInput;
  saturday: ConnectDayOpeningHoursInput;
  sunday: ConnectDayOpeningHoursInput;
};

export type ConnectDayOpeningHoursInput = {
  closedAllDay: Scalars['Boolean'];
  hours: Array<ConnectOpenHourInput>;
};

export type ConnectOpenHourInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type ConnectOpeningHourExceptionInput = {
  type: ConnectOpeningHourExceptionType;
  date: Scalars['String'];
  hours: Array<ConnectOpenHourInput>;
};

export type ConnectCreateApplicationPayload = {
  __typename?: 'ConnectCreateApplicationPayload';
  record?: Maybe<ConnectApplication>;
  errors?: Maybe<Array<ConnectCreateApplicationProblem>>;
};

export type ConnectCreateApplicationProblem = ValidationProblem;

export type ValidationProblem = ProblemInterface & {
  __typename?: 'ValidationProblem';
  message: Scalars['String'];
  validationErrors: Array<ValidationError>;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  path: Array<Scalars['String']>;
  key: Scalars['String'];
  message: Scalars['String'];
  type: Scalars['String'];
};

export type ConnectUpdateApplicationInput = {
  config: ConnectApplicationConfigInput;
  organisationId: Scalars['Int'];
  disabled: Scalars['Boolean'];
};

export type ConnectUpdateApplicationPayload = {
  __typename?: 'ConnectUpdateApplicationPayload';
  record?: Maybe<ConnectApplication>;
  errors?: Maybe<Array<ConnectUpdateApplicationProblem>>;
};

export type ConnectUpdateApplicationProblem = ConnectApplicationDoesNotExistProblem | ValidationProblem;

export type ConnectDeleteApplicationPayload = {
  __typename?: 'ConnectDeleteApplicationPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<ConnectDeleteApplicationProblem>>;
};

export type ConnectDeleteApplicationProblem = ConnectApplicationDoesNotExistProblem;

export type CreateMsdkApplicationParams = {
  name: Scalars['String'];
  fcm?: Maybe<FCMConfig>;
  apns?: Maybe<APNSConfig>;
  sandbox: Scalars['Boolean'];
};

export type FCMConfig = {
  key: Scalars['String'];
};

export type APNSConfig = {
  key: Scalars['String'];
  cert: Scalars['String'];
};

export type UpdateMsdkApplicationParams = {
  name?: Maybe<Scalars['String']>;
  fcm?: Maybe<FCMConfig>;
  apns?: Maybe<APNSConfig>;
  sandbox?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type RulesetParams = {
  id: Scalars['String'];
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  rulesetParameters: Scalars['JSON'];
};

export type RulesetTemplateParams = {
  organisationId: Scalars['Int'];
  accountId: Scalars['Int'];
  template: Scalars['String'];
};

export type GrantAccountAccessPayload = {
  __typename?: 'GrantAccountAccessPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<GrantAccountAccessProblem>>;
};

export type GrantAccountAccessProblem = UserDoesNotExistProblem | AccountDoesNotExistProblem;

export type AccountDoesNotExistProblem = ProblemInterface & {
  __typename?: 'AccountDoesNotExistProblem';
  message: Scalars['String'];
};

export type RevokeAccountAccessPayload = {
  __typename?: 'RevokeAccountAccessPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<RevokeAccountAccessProblem>>;
};

export type RevokeAccountAccessProblem = UserDoesNotExistProblem | AccountDoesNotExistProblem;

export type GrantOrganisationAccessPayload = {
  __typename?: 'GrantOrganisationAccessPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<GrantOrganisationAccessProblem>>;
};

export type GrantOrganisationAccessProblem = UserDoesNotExistProblem;

export type RevokeOrganisationAccessPayload = {
  __typename?: 'RevokeOrganisationAccessPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<RevokeOrganisationAccessProblem>>;
};

export type RevokeOrganisationAccessProblem = UserDoesNotExistProblem;

export type LegacySyncUserCreatePayload = {
  __typename?: 'LegacySyncUserCreatePayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<LegacySyncUserCreateProblem>>>;
};

export type LegacySyncUserCreateProblem = UserDoesNotExistProblem;

export type LegacySyncUserEditInput = {
  flags?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type LegacySyncUserEditPayload = {
  __typename?: 'LegacySyncUserEditPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<LegacySyncUserEditProblem>>>;
};

export type LegacySyncUserEditProblem = UserDoesNotExistProblem;

export type LegacySyncUserRemovePayload = {
  __typename?: 'LegacySyncUserRemovePayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<LegacySyncUserRemoveProblem>>>;
};

export type LegacySyncUserRemoveProblem = UserDoesNotExistProblem;

export type LegacySyncLoginCreatePayload = {
  __typename?: 'LegacySyncLoginCreatePayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<LegacySyncLoginCreateProblem>>>;
};

export type LegacySyncLoginCreateProblem = UserAlreadyExistsProblem;

export type UserAlreadyExistsProblem = ProblemInterface & {
  __typename?: 'UserAlreadyExistsProblem';
  message: Scalars['String'];
};

export type LegacySyncLoginEditInput = {
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type LegacySyncLoginEditPayload = {
  __typename?: 'LegacySyncLoginEditPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<LegacySyncLoginEditProblem>>>;
};

export type LegacySyncLoginEditProblem = UserDoesNotExistProblem;

export type SetUserRolesPayload = {
  __typename?: 'SetUserRolesPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<SetUserRolesProblem>>;
};

export type SetUserRolesProblem = UserDoesNotExistProblem | RoleNotAvailableProblem;

export type RoleNotAvailableProblem = ProblemInterface & {
  __typename?: 'RoleNotAvailableProblem';
  message: Scalars['String'];
  roleId: Scalars['Int'];
};

export type RemoveAllUserRolesPayload = {
  __typename?: 'RemoveAllUserRolesPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<RemoveAllUserRolesProblem>>;
};

export type RemoveAllUserRolesProblem = UserDoesNotExistProblem;

export type UserEditInput = {
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UserEditPayload = {
  __typename?: 'UserEditPayload';
  record?: Maybe<User>;
  errors?: Maybe<Array<UserEditProblem>>;
};

export type UserEditProblem = UserDoesNotExistProblem | ResourceAccessProblem;

export type RoleLockPayload = {
  __typename?: 'RoleLockPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<RoleLockProblem>>;
};

export type RoleLockProblem = RoleNotAvailableProblem;

export type RoleUnlockPayload = {
  __typename?: 'RoleUnlockPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<RoleUnlockProblem>>;
};

export type RoleUnlockProblem = RoleNotAvailableProblem;

export type RoleRemoveForUsersInOrganisationPayload = {
  __typename?: 'RoleRemoveForUsersInOrganisationPayload';
  record?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<RoleRemoveForUsersInOrganisationProblem>>;
};

export type RoleRemoveForUsersInOrganisationProblem = RoleNotAvailableProblem;

export type WorkflowInput = {
  organisationId: Scalars['Int'];
  accountIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  enabledFor: EnabledFor;
  nodes: Array<WorkflowNodeInput>;
};

export type WorkflowNodeInput = {
  id: Scalars['UUID'];
  eventType: EventTypeId;
  parentNodeId?: Maybe<Scalars['UUID']>;
  criteria: Array<NodeCriterionInput>;
  actionType?: Maybe<ActionTypeId>;
  actionValues: Scalars['JSONObject'];
};

export type NodeCriterionInput = {
  property: Scalars['String'];
  comparator: CriterionComparator;
  value?: Maybe<Scalars['IntString']>;
};

export type WorkflowCreatePayload = {
  __typename?: 'WorkflowCreatePayload';
  record?: Maybe<Workflow>;
  errors?: Maybe<Array<WorkflowCreateProblem>>;
};

export type WorkflowCreateProblem = ValidationProblem;

export type WorkflowUpdatePayload = {
  __typename?: 'WorkflowUpdatePayload';
  record?: Maybe<Workflow>;
  errors?: Maybe<Array<WorkflowUpdateProblem>>;
};

export type WorkflowUpdateProblem = ValidationProblem;


export enum IntegrationType {
  WEBHOOK = 'WEBHOOK'
}

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  currency?: Maybe<Scalars['String']>;
  conversionRate?: Maybe<Scalars['Float']>;
  creditMultiple?: Maybe<Scalars['Int']>;
};

import { Colors, FontFamily, getFont } from '@freespee/ui';
import { css } from 'emotion';

import { moduleContentPaddings } from '../card.styles';

export const host = css({
  display: 'block',
  cursor: 'pointer',
});

export const topPart = css({
  borderBottom: `1px solid ${Colors.DAYLIGHT_WHITE}`,

  padding: '15px 24px 8px 24px',

  '& h2': {
    ...getFont(FontFamily.HEADING),
    letterSpacing: 0.35,
    fontWeight: 200,
    fontSize: 32,
  },
});

export const mainContent = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  padding: moduleContentPaddings,
  '& h3': {
    ...getFont(FontFamily.BODY),
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 0.26,
    margin: '0 0 8px 0',
    display: 'inline-block',
  },
  '& p': {
    ...getFont(FontFamily.BODY),
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.5,
    color: Colors.AVERAGE_GREY,
  },
});

export const status = css({
  display: 'inline-block',
  width: 8,
  height: 8,
  borderRadius: 8,
  backgroundColor: Colors.NO_RED,
  marginLeft: 8,
});

export const statusCircle = (backgroundColor?: string) =>
  css({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    borderRadius: 48,
    marginLeft: 48,
    overflow: 'hidden',
    backgroundColor,
  });

export const statusImage = css({
  maxWidth: '100%',
});

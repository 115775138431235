import { Component, HostBinding, Input } from '@angular/core';
import { IconSize } from '@freespee/angular';

import * as css from './more-slots-button.styles';

@Component({
  selector: 'app-more-slots-button',
  template: `
    <button fsButton="secondary">
      {{ text }}</button
    ><fs-icon
      icon="arrow_right"
      [size]="IconSize.SMALL"
      stroke="#F69198"
    ></fs-icon>
  `,
})
export class MoreSlotsButtonComponent {
  IconSize = IconSize;

  constructor() {}

  @HostBinding('class')
  get class() {
    return css.host(this.show);
  }

  @Input()
  show = true;

  @Input()
  text: string;
}

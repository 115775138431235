import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Colors } from '@freespee/ui';

import { commonStyles } from '#app/app.styles';
import { Configuration } from '#app/config/config.interface';
import { Slot } from '#app/scheduling/scheduling.interface';
import * as css from './phone-number-form.styles';
import {
  NumberConfirmedEvent,
  PhoneNumberInput,
} from './phone-number-input.interface';

@Component({
  selector: 'app-phone-number-form',
  template: `
    <form (submit)="onConfirm()">
      <div>
        <h2 [class]="commonStyles.header">
          {{
            configuration.texts[
              'freespeeConnectModuleSchedulePhoneNumberInputTitleBold'
            ]
          }}
        </h2>
        <p [class]="commonStyles.paragraph">
          {{
            configuration.texts[
              'freespeeConnectModuleSchedulePhoneNumberInputTitle'
            ]
          }}
        </p>
        <app-number-input
          (numberChanged)="onNumberChanged($event)"
          [defaultCountryCode]="configuration.defaultCountryCode"
          [placeholder]="
            configuration.texts[
              'freespeeConnectModuleSchedulePhoneNumberInputPlaceHolder'
            ]
          "
        ></app-number-input>
      </div>
      <button
        [class]="css.confirmButton(confirmButtonColors())"
        fsButton="primary"
        [disabled]="!hasEnteredValidNumber()"
        type="submit"
      >
        {{
          configuration.texts[
            'freespeeConnectModuleSchedulePhoneNumberInputScheduleButton'
          ]
        }}
      </button>
    </form>
  `,
})
export class PhoneNumberFormComponent {
  css = css;
  commonStyles = commonStyles;
  enteredNumber?: PhoneNumberInput = undefined;
  constructor() {}

  @Input()
  configuration: Configuration;

  @Input()
  slot: Slot;

  @Output()
  confirm = new EventEmitter<NumberConfirmedEvent>();

  @HostBinding('class')
  hostClass = css.host;

  confirmButtonColors() {
    return this.hasEnteredValidNumber()
      ? {
          textColor: this.configuration.colors.action.invertedText
            ? Colors.WHITE
            : Colors.MIDNIGHT_BLACK,
          backgroundColor: this.configuration.colors.action.color,
        }
      : undefined;
  }

  onNumberChanged(number: PhoneNumberInput) {
    this.enteredNumber = number;
  }

  hasEnteredValidNumber = () => this.enteredNumber && this.enteredNumber.valid;

  onConfirm() {
    if (this.hasEnteredValidNumber()) {
      this.confirm.emit({
        phoneNumber: this.enteredNumber.number,
      });
    }
  }
}

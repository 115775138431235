import { Component, HostBinding, Input } from '@angular/core';

import * as css from './call-module-card.styles';

@Component({
  selector: 'app-call-module-card',
  template: `
    <div [class]="css.topPart">
      <h2>PHONE NO</h2>
    </div>
    <div [class]="css.mainContent">
      <div>
        <h3>{{ header }}</h3>
        <span [class]="css.status"></span>
        <p>{{ body }}</p>
      </div>
      <div>
        <div
          data-cy="status image"
          [class]="
            css.statusCircle(statusImageUrl ? undefined : statusCircleColor)
          "
        >
          <img
            *ngIf="statusImageUrl"
            [class]="css.statusImage"
            src="{{ statusImageUrl }}"
          />
        </div>
      </div>
    </div>
  `,
})
export class CallModuleCardComponent {
  css = css;

  @Input()
  header: string;

  @Input()
  body: string;

  @Input()
  statusCircleColor: string;

  @Input()
  statusImageUrl?: string;

  @HostBinding('class')
  class = css.host;
}

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IconSize } from '@freespee/angular';

import { animationPause, shortAnimation } from '#app/animation-times';
import { requestCloseConnect } from '#app/internal-messaging/internal-messaging';
import { connectLoadedInIframe } from './close.util';
import { HeaderState, HeaderType } from './header.interface';
import * as css from './header.styles';

@Component({
  selector: 'app-header',
  template: `
    <div [class]="css.leftColumn(headerType)">
      <fs-icon
        data-cy="go back"
        (click)="back.emit()"
        icon="chevron_left"
        [size]="IconSize.PRIMARY"
        [stroke]="textColor"
        *ngIf="headerType === HeaderType.MODULE"
      ></fs-icon>
    </div>
    <div>
      <h1
        @headerReappear
        (@headerReappear.done)="onHeaderAnimationDone()"
        *ngIf="headerToShow"
        [class]="css.header(headerType, textColor)"
      >
        {{ headerToShow }}
      </h1>
      <p
        @textReappear
        (@textReappear.done)="onTextAnimationDone()"
        *ngIf="textToShow"
        [class]="css.text(headerType, textColor)"
      >
        {{ textToShow }}
      </p>
    </div>

    <fs-icon
      *ngIf="fullscreenMode"
      [class]="css.closeIcon"
      data-cy="close connect"
      (click)="onCloseClick()"
      icon="close"
      [size]="IconSize.SMALL"
      [stroke]="textColor"
    ></fs-icon>
  `,
  animations: [
    trigger('headerReappear', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [
        animate(
          `${shortAnimation}ms ${shortAnimation + animationPause}ms ease-in-out`
        ),
      ]),
      transition(':leave', [animate(`${shortAnimation}ms ease-in-out`)]),
    ]),
    trigger('textReappear', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [
        animate(
          `${shortAnimation}ms ${shortAnimation + animationPause}ms ease-in-out`
        ),
      ]),
      transition(':leave', [animate(`${shortAnimation}ms ease-in-out`)]),
    ]),
  ],
})
export class HeaderComponent implements OnChanges {
  css = css;
  IconSize = IconSize;
  HeaderType = HeaderType;
  headerState = HeaderState.LARGE;

  @Input()
  header = '';
  // internal copy used to make animations work
  headerToShow = '';

  @Input()
  text = '';
  // internal copy used to make animations work
  textToShow = '';

  @Input()
  fullscreenMode: boolean;

  @Input()
  headerType: HeaderType = HeaderType.MAIN;

  @Input()
  backgroundColor: string;

  @Input()
  textColor: string;

  @Output()
  back = new EventEmitter<void>();

  @HostBinding('class')
  get class() {
    return css.host(this.headerState, this.backgroundColor);
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.header) {
      if (changes.header.previousValue) {
        this.headerToShow = '';
      } else {
        this.headerToShow = this.header;
      }
    }
    if (changes.text) {
      if (changes.text.previousValue) {
        this.textToShow = '';
      } else {
        this.textToShow = this.text;
      }
    }
    if (changes.headerType) {
      if (changes.headerType.previousValue === HeaderType.MAIN) {
        this.headerState = HeaderState.SHRINKING;
      } else if (changes.headerType.previousValue === HeaderType.MODULE) {
        this.headerState = HeaderState.GROWING;
      }
    }
  }

  onCloseClick() {
    // can only be closed if it is shown in iframe
    if (connectLoadedInIframe) {
      requestCloseConnect();
    }
  }

  onHeaderAnimationDone() {
    if (!this.headerToShow && this.header) {
      this.headerToShow = this.header;
    }
    this.headerState =
      this.headerType === HeaderType.MAIN
        ? HeaderState.LARGE
        : HeaderState.SMALL;
  }
  onTextAnimationDone() {
    if (!this.textToShow && this.text) {
      this.textToShow = this.text;
    }
  }
}

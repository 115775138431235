import { FieldError } from '../util/validation.interface';
import { defaultTexts } from './config.constants';
import { ConfigurationUpdate, Mode } from './config.interface';

export interface ValidationLogger {
  error: (message: string, ...objects: Object[]) => void;
  warn: (message: string, ...objects: Object[]) => void;
}
export const validateConfig = (
  config: ConfigurationUpdate,
  logger: ValidationLogger = window.console // for testing logging
): boolean => {
  if (!config) {
    logger.error('Freespee Connect: Invalid configuration', config);
    return false;
  }

  const validConfigParts = [
    'mode',
    'closeOnBlur',
    'texts',
    'defaultCountryCode',
    'companyName',
    'colors',
    'modules',
    'images',
  ];

  Object.keys(config).forEach(key => {
    if (!validConfigParts.includes(key)) {
      logger.warn(
        `Freespee Connect: Invalid configuration. '${key}' is not a valid property of the config object. Valid properties are ${validConfigParts
          .map(mode => `'${mode}'`)
          .join(' ')}`
      );
    }
  });

  if (config.texts) {
    const errors: FieldError[] = Object.keys(defaultTexts)
      .filter(
        key =>
          typeof config.texts[key] !== 'string' ||
          config.texts[key] === undefined
      )
      .map(key => ({
        fieldName: 'texts',
        error:
          config.texts[key] === undefined
            ? `Missing translation key ${key}`
            : `Translation key ${key} should be of type string, was ${typeof config
                .texts[key]}`,
      }));
    if (errors.length > 0) {
      logger.error(
        `Freespee Connect: Invalid text configuration: ${errors
          .map(validation => validation.error)
          .join(', ')}`
      );
      return false;
    }
  }

  if (config.mode) {
    const validModes = Object.values(Mode);
    if (!validModes.includes(config.mode)) {
      logger.error(
        `Freespee Connect: Invalid mode in configuration '${
          config.mode
        }'. Valid modes are ${validModes.map(mode => `'${mode}'`).join(' ')}`
      );
      return false;
    }
  }

  return true;
};

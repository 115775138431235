export interface CountryCodeOption {
  labelWhenClosed: string;
  label: string;
  value: string;
}

export const countryCodeOptions: CountryCodeOption[] = [
  { label: 'United States', value: 'us', labelWhenClosed: 'us' },
  { label: 'United Kingdom', value: 'gb', labelWhenClosed: 'gb' },
  {
    label: 'Afghanistan (‫افغانستان‬‎)',
    value: 'af',
    labelWhenClosed: 'af',
  },
  { label: 'Albania (Shqipëri)', value: 'al', labelWhenClosed: 'al' },
  { label: 'Algeria (‫الجزائر‬‎)', value: 'dz', labelWhenClosed: 'dz' },
  { label: 'American Samoa', value: 'as', labelWhenClosed: 'as' },
  { label: 'Andorra', value: 'ad', labelWhenClosed: 'ad' },
  { label: 'Angola', value: 'ao', labelWhenClosed: 'ao' },
  { label: 'Anguilla', value: 'ai', labelWhenClosed: 'ai' },
  { label: 'Antigua and Barbuda', value: 'ag', labelWhenClosed: 'ag' },
  { label: 'Argentina', value: 'ar', labelWhenClosed: 'ar' },
  { label: 'Armenia (Հայաստան)', value: 'am', labelWhenClosed: 'am' },
  { label: 'Aruba', value: 'aw', labelWhenClosed: 'aw' },
  { label: 'Australia', value: 'au', labelWhenClosed: 'au' },
  { label: 'Austria (Österreich)', value: 'at', labelWhenClosed: 'at' },
  { label: 'Azerbaijan (Azərbaycan)', value: 'az', labelWhenClosed: 'az' },
  { label: 'Bahamas', value: 'bs', labelWhenClosed: 'bs' },
  { label: 'Bahrain (‫البحرين‬‎)', value: 'bh', labelWhenClosed: 'bh' },
  { label: 'Bangladesh (বাংলাদেশ)', value: 'bd', labelWhenClosed: 'bd' },
  { label: 'Barbados', value: 'bb', labelWhenClosed: 'bb' },
  { label: 'Belarus (Беларусь)', value: 'by', labelWhenClosed: 'by' },
  { label: 'Belgium (België)', value: 'be', labelWhenClosed: 'be' },
  { label: 'Belize', value: 'bz', labelWhenClosed: 'bz' },
  { label: 'Benin (Bénin)', value: 'bj', labelWhenClosed: 'bj' },
  { label: 'Bermuda', value: 'bm', labelWhenClosed: 'bm' },
  { label: 'Bhutan (འབྲུག)', value: 'bt', labelWhenClosed: 'bt' },
  { label: 'Bolivia', value: 'bo', labelWhenClosed: 'bo' },
  {
    label: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    value: 'ba',
    labelWhenClosed: 'ba',
  },
  { label: 'Botswana', value: 'bw', labelWhenClosed: 'bw' },
  { label: 'Brazil (Brasil)', value: 'br', labelWhenClosed: 'br' },
  {
    label: 'British Indian Ocean Territory',
    value: 'io',
    labelWhenClosed: 'io',
  },
  { label: 'British Virgin Islands', value: 'vg', labelWhenClosed: 'vg' },
  { label: 'Brunei', value: 'bn', labelWhenClosed: 'bn' },
  { label: 'Bulgaria (България)', value: 'bg', labelWhenClosed: 'bg' },
  { label: 'Burkina Faso', value: 'bf', labelWhenClosed: 'bf' },
  { label: 'Burundi (Uburundi)', value: 'bi', labelWhenClosed: 'bi' },
  { label: 'Cambodia (កម្ពុជា)', value: 'kh', labelWhenClosed: 'kh' },
  { label: 'Cameroon (Cameroun)', value: 'cm', labelWhenClosed: 'cm' },
  { label: 'Canada', value: 'ca', labelWhenClosed: 'ca' },
  { label: 'Cape Verde (Kabu Verdi)', value: 'cv', labelWhenClosed: 'cv' },
  { label: 'Caribbean Netherlands', value: 'bq', labelWhenClosed: 'bq' },
  { label: 'Cayman Islands', value: 'ky', labelWhenClosed: 'ky' },
  {
    label: 'Central African Republic (République centrafricaine)',
    value: 'cf',
    labelWhenClosed: 'cf',
  },
  { label: 'Chad (Tchad)', value: 'td', labelWhenClosed: 'td' },
  { label: 'Chile', value: 'cl', labelWhenClosed: 'cl' },
  { label: 'China (中国)', value: 'cn', labelWhenClosed: 'cn' },
  { label: 'Christmas Island', value: 'cx', labelWhenClosed: 'cx' },
  { label: 'Cocos (Keeling) Islands', value: 'cc', labelWhenClosed: 'cc' },
  { label: 'Colombia', value: 'co', labelWhenClosed: 'co' },
  { label: 'Comoros (‫جزر القمر‬‎)', value: 'km', labelWhenClosed: 'km' },
  {
    label: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    value: 'cd',
    labelWhenClosed: 'cd',
  },
  {
    label: 'Congo (Republic) (Congo-Brazzaville)',
    value: 'cg',
    labelWhenClosed: 'cg',
  },
  { label: 'Cook Islands', value: 'ck', labelWhenClosed: 'ck' },
  { label: 'Costa Rica', value: 'cr', labelWhenClosed: 'cr' },
  { label: 'Côte d’Ivoire', value: 'ci', labelWhenClosed: 'ci' },
  { label: 'Croatia (Hrvatska)', value: 'hr', labelWhenClosed: 'hr' },
  { label: 'Cuba', value: 'cu', labelWhenClosed: 'cu' },
  { label: 'Curaçao', value: 'cw', labelWhenClosed: 'cw' },
  { label: 'Cyprus (Κύπρος)', value: 'cy', labelWhenClosed: 'cy' },
  {
    label: 'Czech Republic (Česká republika)',
    value: 'cz',
    labelWhenClosed: 'cz',
  },
  { label: 'Denmark (Danmark)', value: 'dk', labelWhenClosed: 'dk' },
  { label: 'Djibouti', value: 'dj', labelWhenClosed: 'dj' },
  { label: 'Dominica', value: 'dm', labelWhenClosed: 'dm' },
  {
    label: 'Dominican Republic (República Dominicana)',
    value: 'do',
    labelWhenClosed: 'do',
  },
  { label: 'Ecuador', value: 'ec', labelWhenClosed: 'ec' },
  { label: 'Egypt (‫مصر‬‎)', value: 'eg', labelWhenClosed: 'eg' },
  { label: 'El Salvador', value: 'sv', labelWhenClosed: 'sv' },
  {
    label: 'Equatorial Guinea (Guinea Ecuatorial)',
    value: 'gq',
    labelWhenClosed: 'gq',
  },
  { label: 'Eritrea', value: 'er', labelWhenClosed: 'er' },
  { label: 'Estonia (Eesti)', value: 'ee', labelWhenClosed: 'ee' },
  { label: 'Ethiopia', value: 'et', labelWhenClosed: 'et' },
  {
    label: 'Falkland Islands (Islas Malvinas)',
    value: 'fk',
    labelWhenClosed: 'fk',
  },
  { label: 'Faroe Islands (Føroyar)', value: 'fo', labelWhenClosed: 'fo' },
  { label: 'Fiji', value: 'fj', labelWhenClosed: 'fj' },
  { label: 'Finland (Suomi)', value: 'fi', labelWhenClosed: 'fi' },
  { label: 'France', value: 'fr', labelWhenClosed: 'fr' },
  {
    label: 'French Guiana (Guyane française)',
    value: 'gf',
    labelWhenClosed: 'gf',
  },
  {
    label: 'French Polynesia (Polynésie française)',
    value: 'pf',
    labelWhenClosed: 'pf',
  },
  { label: 'Gabon', value: 'ga', labelWhenClosed: 'ga' },
  { label: 'Gambia', value: 'gm', labelWhenClosed: 'gm' },
  { label: 'Georgia (საქართველო)', value: 'ge', labelWhenClosed: 'ge' },
  { label: 'Germany (Deutschland)', value: 'de', labelWhenClosed: 'de' },
  { label: 'Ghana (Gaana)', value: 'gh', labelWhenClosed: 'gh' },
  { label: 'Gibraltar', value: 'gi', labelWhenClosed: 'gi' },
  { label: 'Greece (Ελλάδα)', value: 'gr', labelWhenClosed: 'gr' },
  {
    label: 'Greenland (Kalaallit Nunaat)',
    value: 'gl',
    labelWhenClosed: 'gl',
  },
  { label: 'Grenada', value: 'gd', labelWhenClosed: 'gd' },
  { label: 'Guadeloupe', value: 'gp', labelWhenClosed: 'gp' },
  { label: 'Guam', value: 'gu', labelWhenClosed: 'gu' },
  { label: 'Guatemala', value: 'gt', labelWhenClosed: 'gt' },
  { label: 'Guernsey', value: 'gg', labelWhenClosed: 'gg' },
  { label: 'Guinea (Guinée)', value: 'gn', labelWhenClosed: 'gn' },
  {
    label: 'Guinea-Bissau (Guiné Bissau)',
    value: 'gw',
    labelWhenClosed: 'gw',
  },
  { label: 'Guyana', value: 'gy', labelWhenClosed: 'gy' },
  { label: 'Haiti', value: 'ht', labelWhenClosed: 'ht' },
  { label: 'Honduras', value: 'hn', labelWhenClosed: 'hn' },
  { label: 'Hong Kong (香港)', value: 'hk', labelWhenClosed: 'hk' },
  { label: 'Hungary (Magyarország)', value: 'hu', labelWhenClosed: 'hu' },
  { label: 'Iceland (Ísland)', value: 'is', labelWhenClosed: 'is' },
  { label: 'India (भारत)', value: 'in', labelWhenClosed: 'in' },
  { label: 'Indonesia', value: 'id', labelWhenClosed: 'id' },
  { label: 'Iran (‫ایران‬‎)', value: 'ir', labelWhenClosed: 'ir' },
  { label: 'Iraq (‫العراق‬‎)', value: 'iq', labelWhenClosed: 'iq' },
  { label: 'Ireland', value: 'ie', labelWhenClosed: 'ie' },
  { label: 'Isle of Man', value: 'im', labelWhenClosed: 'im' },
  { label: 'Israel (‫ישראל‬‎)', value: 'il', labelWhenClosed: 'il' },
  { label: 'Italy (Italia)', value: 'it', labelWhenClosed: 'it' },
  { label: 'Jamaica', value: 'jm', labelWhenClosed: 'jm' },
  { label: 'Japan (日本)', value: 'jp', labelWhenClosed: 'jp' },
  { label: 'Jersey', value: 'je', labelWhenClosed: 'je' },
  { label: 'Jordan (‫الأردن‬‎)', value: 'jo', labelWhenClosed: 'jo' },
  { label: 'Kazakhstan (Казахстан)', value: 'kz', labelWhenClosed: 'kz' },
  { label: 'Kenya', value: 'ke', labelWhenClosed: 'ke' },
  { label: 'Kiribati', value: 'ki', labelWhenClosed: 'ki' },
  { label: 'Kosovo', value: 'xk', labelWhenClosed: 'xk' },
  { label: 'Kuwait (‫الكويت‬‎)', value: 'kw', labelWhenClosed: 'kw' },
  { label: 'Kyrgyzstan (Кыргызстан)', value: 'kg', labelWhenClosed: 'kg' },
  { label: 'Laos (ລາວ)', value: 'la', labelWhenClosed: 'la' },
  { label: 'Latvia (Latvija)', value: 'lv', labelWhenClosed: 'lv' },
  { label: 'Lebanon (‫لبنان‬‎)', value: 'lb', labelWhenClosed: 'lb' },
  { label: 'Lesotho', value: 'ls', labelWhenClosed: 'ls' },
  { label: 'Liberia', value: 'lr', labelWhenClosed: 'lr' },
  { label: 'Libya (‫ليبيا‬‎)', value: 'ly', labelWhenClosed: 'ly' },
  { label: 'Liechtenstein', value: 'li', labelWhenClosed: 'li' },
  { label: 'Lithuania (Lietuva)', value: 'lt', labelWhenClosed: 'lt' },
  { label: 'Luxembourg', value: 'lu', labelWhenClosed: 'lu' },
  { label: 'Macau (澳門)', value: 'mo', labelWhenClosed: 'mo' },
  {
    label: 'Macedonia (FYROM) (Македонија)',
    value: 'mk',
    labelWhenClosed: 'mk',
  },
  {
    label: 'Madagascar (Madagasikara)',
    value: 'mg',
    labelWhenClosed: 'mg',
  },
  { label: 'Malawi', value: 'mw', labelWhenClosed: 'mw' },
  { label: 'Malaysia', value: 'my', labelWhenClosed: 'my' },
  { label: 'Maldives', value: 'mv', labelWhenClosed: 'mv' },
  { label: 'Mali', value: 'ml', labelWhenClosed: 'ml' },
  { label: 'Malta', value: 'mt', labelWhenClosed: 'mt' },
  { label: 'Marshall Islands', value: 'mh', labelWhenClosed: 'mh' },
  { label: 'Martinique', value: 'mq', labelWhenClosed: 'mq' },
  {
    label: 'Mauritania (‫موريتانيا‬‎)',
    value: 'mr',
    labelWhenClosed: 'mr',
  },
  { label: 'Mauritius (Moris)', value: 'mu', labelWhenClosed: 'mu' },
  { label: 'Mayotte', value: 'yt', labelWhenClosed: 'yt' },
  { label: 'Mexico (México)', value: 'mx', labelWhenClosed: 'mx' },
  { label: 'Micronesia', value: 'fm', labelWhenClosed: 'fm' },
  {
    label: 'Moldova (Republica Moldova)',
    value: 'md',
    labelWhenClosed: 'md',
  },
  { label: 'Monaco', value: 'mc', labelWhenClosed: 'mc' },
  { label: 'Mongolia (Монгол)', value: 'mn', labelWhenClosed: 'mn' },
  { label: 'Montenegro (Crna Gora)', value: 'me', labelWhenClosed: 'me' },
  { label: 'Montserrat', value: 'ms', labelWhenClosed: 'ms' },
  { label: 'Morocco (‫المغرب‬‎)', value: 'ma', labelWhenClosed: 'ma' },
  { label: 'Mozambique (Moçambique)', value: 'mz', labelWhenClosed: 'mz' },
  { label: 'Myanmar (Burma) (မြန်မာ)', value: 'mm', labelWhenClosed: 'mm' },
  { label: 'Namibia (Namibië)', value: 'na', labelWhenClosed: 'na' },
  { label: 'Nauru', value: 'nr', labelWhenClosed: 'nr' },
  { label: 'Nepal (नेपाल)', value: 'np', labelWhenClosed: 'np' },
  { label: 'Netherlands (Nederland)', value: 'nl', labelWhenClosed: 'nl' },
  {
    label: 'New Caledonia (Nouvelle-Calédonie)',
    value: 'nc',
    labelWhenClosed: 'nc',
  },
  { label: 'New Zealand', value: 'nz', labelWhenClosed: 'nz' },
  { label: 'Nicaragua', value: 'ni', labelWhenClosed: 'ni' },
  { label: 'Niger (Nijar)', value: 'ne', labelWhenClosed: 'ne' },
  { label: 'Nigeria', value: 'ng', labelWhenClosed: 'ng' },
  { label: 'Niue', value: 'nu', labelWhenClosed: 'nu' },
  { label: 'Norfolk Island', value: 'nf', labelWhenClosed: 'nf' },
  {
    label: 'North Korea (조선 민주주의 인민 공화국)',
    value: 'kp',
    labelWhenClosed: 'kp',
  },
  { label: 'Northern Mariana Islands', value: 'mp', labelWhenClosed: 'mp' },
  { label: 'Norway (Norge)', value: 'no', labelWhenClosed: 'no' },
  { label: 'Oman (‫عُمان‬‎)', value: 'om', labelWhenClosed: 'om' },
  { label: 'Pakistan (‫پاکستان‬‎)', value: 'pk', labelWhenClosed: 'pk' },
  { label: 'Palau', value: 'pw', labelWhenClosed: 'pw' },
  { label: 'Palestine (‫فلسطين‬‎)', value: 'ps', labelWhenClosed: 'ps' },
  { label: 'Panama (Panamá)', value: 'pa', labelWhenClosed: 'pa' },
  { label: 'Papua New Guinea', value: 'pg', labelWhenClosed: 'pg' },
  { label: 'Paraguay', value: 'py', labelWhenClosed: 'py' },
  { label: 'Peru (Perú)', value: 'pe', labelWhenClosed: 'pe' },
  { label: 'Philippines', value: 'ph', labelWhenClosed: 'ph' },
  { label: 'Poland (Polska)', value: 'pl', labelWhenClosed: 'pl' },
  { label: 'Portugal', value: 'pt', labelWhenClosed: 'pt' },
  { label: 'Puerto Rico', value: 'pr', labelWhenClosed: 'pr' },
  { label: 'Qatar (‫قطر‬‎)', value: 'qa', labelWhenClosed: 'qa' },
  { label: 'Réunion (La Réunion)', value: 're', labelWhenClosed: 're' },
  { label: 'Romania (România)', value: 'ro', labelWhenClosed: 'ro' },
  { label: 'Russia (Россия)', value: 'ru', labelWhenClosed: 'ru' },
  { label: 'Rwanda', value: 'rw', labelWhenClosed: 'rw' },
  { label: 'Saint Barthélemy', value: 'bl', labelWhenClosed: 'bl' },
  { label: 'Saint Helena', value: 'sh', labelWhenClosed: 'sh' },
  { label: 'Saint Kitts and Nevis', value: 'kn', labelWhenClosed: 'kn' },
  { label: 'Saint Lucia', value: 'lc', labelWhenClosed: 'lc' },
  {
    label: 'Saint Martin (Saint-Martin (partie française))',
    value: 'mf',
    labelWhenClosed: 'mf',
  },
  {
    label: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    value: 'pm',
    labelWhenClosed: 'pm',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'vc',
    labelWhenClosed: 'vc',
  },
  { label: 'Samoa', value: 'ws', labelWhenClosed: 'ws' },
  { label: 'San Marino', value: 'sm', labelWhenClosed: 'sm' },
  {
    label: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    value: 'st',
    labelWhenClosed: 'st',
  },
  {
    label: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    value: 'sa',
    labelWhenClosed: 'sa',
  },
  { label: 'Senegal (Sénégal)', value: 'sn', labelWhenClosed: 'sn' },
  { label: 'Serbia (Србија)', value: 'rs', labelWhenClosed: 'rs' },
  { label: 'Seychelles', value: 'sc', labelWhenClosed: 'sc' },
  { label: 'Sierra Leone', value: 'sl', labelWhenClosed: 'sl' },
  { label: 'Singapore', value: 'sg', labelWhenClosed: 'sg' },
  { label: 'Sint Maarten', value: 'sx', labelWhenClosed: 'sx' },
  { label: 'Slovakia (Slovensko)', value: 'sk', labelWhenClosed: 'sk' },
  { label: 'Slovenia (Slovenija)', value: 'si', labelWhenClosed: 'si' },
  { label: 'Solomon Islands', value: 'sb', labelWhenClosed: 'sb' },
  { label: 'Somalia (Soomaaliya)', value: 'so', labelWhenClosed: 'so' },
  { label: 'South Africa', value: 'za', labelWhenClosed: 'za' },
  { label: 'South Korea (대한민국)', value: 'kr', labelWhenClosed: 'kr' },
  {
    label: 'South Sudan (‫جنوب السودان‬‎)',
    value: 'ss',
    labelWhenClosed: 'ss',
  },
  { label: 'Spain (España)', value: 'es', labelWhenClosed: 'es' },
  { label: 'Sri Lanka (ශ්‍රී ලංකාව)', value: 'lk', labelWhenClosed: 'lk' },
  { label: 'Sudan (‫السودان‬‎)', value: 'sd', labelWhenClosed: 'sd' },
  { label: 'Suriname', value: 'sr', labelWhenClosed: 'sr' },
  { label: 'Svalbard and Jan Mayen', value: 'sj', labelWhenClosed: 'sj' },
  { label: 'Swaziland', value: 'sz', labelWhenClosed: 'sz' },
  { label: 'Sweden (Sverige)', value: 'se', labelWhenClosed: 'se' },
  { label: 'Switzerland (Schweiz)', value: 'ch', labelWhenClosed: 'ch' },
  { label: 'Syria (‫سوريا‬‎)', value: 'sy', labelWhenClosed: 'sy' },
  { label: 'Taiwan (台灣)', value: 'tw', labelWhenClosed: 'tw' },
  { label: 'Tajikistan', value: 'tj', labelWhenClosed: 'tj' },
  { label: 'Tanzania', value: 'tz', labelWhenClosed: 'tz' },
  { label: 'Thailand (ไทย)', value: 'th', labelWhenClosed: 'th' },
  { label: 'Timor-Leste', value: 'tl', labelWhenClosed: 'tl' },
  { label: 'Togo', value: 'tg', labelWhenClosed: 'tg' },
  { label: 'Tokelau', value: 'tk', labelWhenClosed: 'tk' },
  { label: 'Tonga', value: 'to', labelWhenClosed: 'to' },
  { label: 'Trinidad and Tobago', value: 'tt', labelWhenClosed: 'tt' },
  { label: 'Tunisia (‫تونس‬‎)', value: 'tn', labelWhenClosed: 'tn' },
  { label: 'Turkey (Türkiye)', value: 'tr', labelWhenClosed: 'tr' },
  { label: 'Turkmenistan', value: 'tm', labelWhenClosed: 'tm' },
  { label: 'Turks and Caicos Islands', value: 'tc', labelWhenClosed: 'tc' },
  { label: 'Tuvalu', value: 'tv', labelWhenClosed: 'tv' },
  { label: 'U.S. Virgin Islands', value: 'vi', labelWhenClosed: 'vi' },
  { label: 'Uganda', value: 'ug', labelWhenClosed: 'ug' },
  { label: 'Ukraine (Україна)', value: 'ua', labelWhenClosed: 'ua' },
  {
    label: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    value: 'ae',
    labelWhenClosed: 'ae',
  },
  { label: 'United Kingdom', value: 'gb', labelWhenClosed: 'gb' },
  { label: 'United States', value: 'us', labelWhenClosed: 'us' },
  { label: 'Uruguay', value: 'uy', labelWhenClosed: 'uy' },
  { label: 'Uzbekistan (Oʻzbekiston)', value: 'uz', labelWhenClosed: 'uz' },
  { label: 'Vanuatu', value: 'vu', labelWhenClosed: 'vu' },
  {
    label: 'Vatican City (Città del Vaticano)',
    value: 'va',
    labelWhenClosed: 'va',
  },
  { label: 'Venezuela', value: 've', labelWhenClosed: 've' },
  { label: 'Vietnam (Việt Nam)', value: 'vn', labelWhenClosed: 'vn' },
  {
    label: 'Wallis and Futuna (Wallis-et-Futuna)',
    value: 'wf',
    labelWhenClosed: 'wf',
  },
  {
    label: 'Western Sahara (‫الصحراء الغربية‬‎)',
    value: 'eh',
    labelWhenClosed: 'eh',
  },
  { label: 'Yemen (‫اليمن‬‎)', value: 'ye', labelWhenClosed: 'ye' },
  { label: 'Zambia', value: 'zm', labelWhenClosed: 'zm' },
  { label: 'Zimbabwe', value: 'zw', labelWhenClosed: 'zw' },
  { label: 'Åland Islands', value: 'ax', labelWhenClosed: 'ax' },
];

import { Colors, FontFamily, FontWeight, getFont } from '@freespee/ui';
import { css } from 'emotion';

import { LARGE_MARGIN, SMALL_MARGIN } from '../../../app.styles';

export const paddingTop = LARGE_MARGIN;

export const fadeDuration = 250;
export const selectSlideDuration = 250;
export const selectSlideDelay = fadeDuration;

export const deselectSlideDuration = 100;
export const deselectSlideDelay = 100;

const slideInNumberInputDuration = 200;

const slideInNumberInputDelay = fadeDuration + slideInNumberInputDuration + 100;

export const host = (allowScroll: boolean) =>
  css({
    backgroundColor: Colors.BACKGROUND,
    display: 'block',
    overflow: allowScroll ? 'auto' : 'hidden',
    position: 'relative',
    height: '100%',
  });

export const slotLists = (show: boolean) =>
  css({
    height: '100%',
    boxSizing: 'border-box',

    opacity: show ? 1 : 0,
    transition: `opacity ${fadeDuration}ms ease-in-out`,
  });

export const suggestedSlots = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingTop: `${paddingTop}px`,
  minHeight: '100%',
  boxSizing: 'border-box',
});

export const allSlots = css({
  width: '100%',
  paddingBottom: SMALL_MARGIN,
});

export const slotGroupHeader = css({
  '& *': {
    ...getFont(FontFamily.BODY),
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.3,
  },
  '& span': {
    fontWeight: 'normal',
    marginLeft: 12,
    color: Colors.AVERAGE_GREY,
  },
  '& h3': {
    margin: 0,
  },
  margin: `24px 0 12px ${LARGE_MARGIN}px`,
  display: 'flex',
  alignItems: 'center',
});

export const selectedSlotContainer = css({
  position: 'absolute',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

export const selectedSlot = (initialOffsetY: number, scrollTop: number) =>
  css({
    '&.slideUp': {
      transition: `
        transform ${selectSlideDuration}ms ease-in-out ${selectSlideDelay}ms,
        margin ${selectSlideDuration}ms linear ${selectSlideDelay}ms,
        stroke ${selectSlideDuration}ms ease-in-out ${selectSlideDelay}ms
      `,
      transform: `translateY(${scrollTop}px)`,
      margin: 0,
      stroke: 'green',
    },
    '&.slideDown': {
      transition: `transform ${deselectSlideDuration}ms ease-in-out, margin ${deselectSlideDuration}ms linear`,
      transform: `translateY(${initialOffsetY}px)`,
    },
    '& fs-small-card h2': {
      fontWeight: FontWeight.BOOK,
    },
  });

export const phoneNumberInputContainer = (scrollTop: number) =>
  css({
    flexGrow: 1,
    boxSizing: 'border-box',
    overflow: 'scroll',
    padding: LARGE_MARGIN,

    '&.slideUp': {
      transition: `transform ${slideInNumberInputDuration}ms ease-in-out ${slideInNumberInputDelay}ms`,
      transform: `translateY(${scrollTop}px)`,
    },
    '&.slideDown': {
      transition: `transform ${slideInNumberInputDuration}ms ease-in-out`,
      transform: `translateY(calc(125% + ${scrollTop}px))`,
    },
  });

export const cardLoading = css({
  display: 'block',
  '& fs-skeleton-card-small': {
    boxSizing: 'border-box',
  },
  margin: `0 ${SMALL_MARGIN}px 8px ${SMALL_MARGIN}px`,
});
